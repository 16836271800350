import { formattedRequest } from '@/utils/functions';

const XNotificationsPlugin = {};

XNotificationsPlugin.install = (Vue) => {
    Vue.prototype.$showNotify = function (type, message, timeout, toManager, actionButton, closeButton) {
        // Vuex call
        if (typeof message !== 'string' && message?.length) {
            message?.forEach((content) => {
                this.$showNotify(type, content, timeout, toManager, actionButton, closeButton);
            });
            return true;
        }
        this.$store.dispatch('global_notifications_store/showNotify', formattedRequest({
            type,
            message,
            timeout,
            toManager,
            actionButton,
            closeButton,
        }));
    };
    Vue.prototype.$showError = function (message, timeout, toManager, ago) {
        this.$showNotify('error', message, timeout, toManager, null, null, ago);
    };
    Vue.prototype.$showSuccess = function (message, timeout, toManager, ago) {
        this.$showNotify('success', message, timeout, toManager, null, null, ago);
    };
    Vue.prototype.$showWarning = function (message, timeout, toManager, ago) {
        this.$showNotify('warning', message, timeout, toManager, null, null, ago);
    };
    Vue.prototype.$showInformation = function (message, timeout, toManager, ago) {
        this.$showNotify('info', message, timeout, toManager, null, null, ago);
    };
    Vue.prototype.$closeNotify = function () {
        this.$store.dispatch('global_notifications_store/cleanNotifications');
    };
};

export default XNotificationsPlugin;
