/**
 * Custom DatetimePicker. Based on DateRangePicker.
 *
 * Use $('input[name="dates"]').customdatetimepicker(); for creation.
 *
 * You can access the Custom Datetime Picker object and its functions and properties through data properties of the element you attached it to.
 * var timePicker = $('#datetime').data('customdatetimepicker');
 *
 * Settable options:
 * - value string - start date. If value is empty current time will be set to start date/
 * - timePickerSeconds boolean - default false - remove seconds from time formats if it false.
 * - timePickerIncrement integer - increment for timeselect in minutes.
 * - allowTimeInPast boolean - default true - disable datetime in past if it false.
 * - oneInputForTime - use one input for time instead of selects. Can be used only for single date picker.
 *
 * Useful options:
 * - dateTimeFormat (without seconds if timePickerSeconds = false)
 * - phpTimeFormat (without seconds if timePickerSeconds = false)
 * - jsTimeFormat (without seconds if timePickerSeconds = false)
 *
 * Useful functions:
 * - resetDatesToNow() - set rounded current datetime to start and end dates.
 * - getDatePickerData() - get DateRangePicker object.
 *
 * @param element
 * @param daterangepickerConfigs configs for DateRangePicker
 * @param options configs for Custom DatetimePicker
 * @param callback for DateRangePicker
 * @constructor
 */
window.CustomDatetimePicker = null;
$(() => {
    window.CustomDatetimePicker = function (element, daterangepickerConfigs, options, callback) {
        this.element = $(element);
        this.value = null;

        this.timePickerSeconds = false;
        this.timePickerIncrement = 5;
        this.allowTimeInPast = true;
        this.oneInputForTime = true;
        this.showRanges = false;

        this.dateTimeFormat = window.datetime_format;
        this.phpTimeFormat = window.php_time_format;
        this.jsTimeFormat = window.js_time_format;
        this.time24Hour = window.time_24_hour;
        this.ranges = $.extend(true, {}, window.daterangepicker_single_default_ranges);

        let self = this;

        // custom options from user
        if (typeof options !== 'object' || options === null) {
            options = {};
        }

        if (typeof options.value === 'string' && options.value && options.value !== '0000-00-00 00:00:00') {
            this.value = moment(options.value);
        } else if (!this.element.is('input[type=text]') || !this.element.val()) { // startDate will be set from field if it has value
            this.value = this.getRoundedCurrentMoment();
        }

        if (typeof options.timePickerSeconds === 'boolean') {
            this.timePickerSeconds = options.timePickerSeconds;
        }

        if (typeof options.timePickerIncrement === 'number') {
            this.timePickerIncrement = options.timePickerIncrement;
        }

        if (typeof options.allowTimeInPast === 'boolean') {
            this.allowTimeInPast = options.allowTimeInPast;
        }

        if (typeof options.oneInputForTime === 'boolean') {
            this.oneInputForTime = options.oneInputForTime;
        }

        if (typeof options.showRanges === 'boolean') {
            this.showRanges = options.showRanges;
        }

        if (typeof options.ranges === 'object') {
            this.ranges = options.ranges;
        }

        this.daterangepickerConfig = $.extend(true, {}, daterangepickerConfigs);

        if (!this.daterangepickerConfig.timePicker || !this.daterangepickerConfig.singleDatePicker) {
            this.oneInputForTime = false;
        }

        this.daterangepickerConfig.timePickerSeconds = this.timePickerSeconds;

        // If seconds was disabled but we need it (@see SPL-8165)
        if (this.timePickerSeconds) {
            this.dateTimeFormat = this.dateTimeFormat.replace(':ss', '').replace(':mm', ':mm:ss');
            this.jsTimeFormat = this.jsTimeFormat.replace(':ss', '').replace(':mm', ':mm:ss');
            this.phpTimeFormat = this.phpTimeFormat.replace(':s', '').replace(':i', ':i:s');
        } else {
            this.dateTimeFormat = this.dateTimeFormat.replace(':ss', '');
            this.jsTimeFormat = this.jsTimeFormat.replace(':ss', '');
            this.phpTimeFormat = this.phpTimeFormat.replace(':s', '');
        }

        if (!this.allowTimeInPast) {
            this.daterangepickerConfig.minDate = moment();
        }

        if (this.value) {
            this.daterangepickerConfig.startDate = this.value;
        }

        if (this.showRanges) {
            $.each(this.ranges, (index, value) => {
                $.each(value, (index2, value2) => {
                    self.ranges[index][index2] = self.roundTime(value2);
                });
            });

            this.daterangepickerConfig.ranges = window.daterangepicker_single_default_ranges;
        }

        // Create daterangepicker
        this.element.daterangepicker(this.daterangepickerConfig, callback);

        if (this.oneInputForTime) {
            loadInputmask(this.renderTimeInput.bind(this));
        }

        if (this.showRanges) {
            this.getContainer().find('.ranges ul').show();
        }

        this.element.on('apply.daterangepicker', (ev, picker) => {
            self.element.val(picker.startDate.locale('en').format(self.dateTimeFormat)).trigger('change'); // set en locale for correct parsing 12h time
        });

        if (this.oneInputForTime) {
            this.element.on('cancel.daterangepicker', () => {
                self.resetTimeselectValue();
            });
        }

        // replace function setStartDate in daterangepicker. set min date in timepicker after date changing
        let setDateFunction = this.getDatePickerData().setStartDate.bind(this.getDatePickerData());
        this.getDatePickerData().setStartDate = function (startDate, isModal) {
            startDate = moment(startDate, this.locale.format);
            let time = self.getTimeFromTimeselect();
            if (!isModal && time.isValid() && startDate.format(self.jsTimeFormat) !== time.format(self.jsTimeFormat)) {
                startDate.hours(time.hours()).minutes(time.minutes()).seconds(time.seconds());
                startDate = moment(startDate._i, startDate._f);
            }
            self.resetTimeselectValue(startDate);
            setDateFunction(startDate);

            if (!self.allowTimeInPast) {
                let minTime = '00:00';
                if (self.getStartDate().isSame(moment(), 'day')) {
                    minTime = self.roundTime(moment()).locale('en').format(self.jsTimeFormat); // set en locale for correct parsing 12h time
                }

                self.getContainer().find('.timeselect').timepicker('option', 'minTime', minTime);
            }
        };

        this.element.on('change', function () {
            if (!empty($(this).val())) {
                let newDate = moment($(this).val(), self.dateTimeFormat, 'en');
                if (newDate.isValid()) {
                    if (self.getStartDate().format('LLL') === newDate.format('LLL')) {
                        self.resetTimeselectValue();
                    } else {
                        self.setDates(newDate);
                    }
                }
            }
        });

        this.getContainer().on('change', '.timeselect', () => {
            let time = self.getTimeFromTimeselect();
            if (time.isValid()) {
                let startDate = self.getDatePickerData().startDate.clone();
                startDate.hours(time.hours()).minutes(time.minutes()).seconds(time.seconds());
                self.setDates(startDate);
                // reset selected range
                self.getContainer().find('.ranges li.active').removeClass('active');
            }
        });
    };

    window.CustomDatetimePicker.prototype = {
        constructor: window.CustomDatetimePicker,

        getTimeFromTimeselect() {
            let time = this.getContainer().find('.timeselect').val();

            return moment(time, this.jsTimeFormat, 'en'); // set en locale for correct parsing 12h time
        },

        getDatePickerData() {
            return this.element.data('daterangepicker');
        },

        getContainer() {
            return this.getDatePickerData().container;
        },

        getStartDate() {
            return this.getDatePickerData().startDate;
        },

        roundTime(moment) {
            if (this.timePickerIncrement > 1) {
                moment.minute(Math.ceil(moment.minute() / this.timePickerIncrement) * this.timePickerIncrement);
            }

            return moment;
        },

        getRoundedCurrentMoment() {
            let startDate = moment();
            if (this.timePickerIncrement > 1) {
                startDate.minutes(Math.round(startDate.minutes() / this.timePickerIncrement) * this.timePickerIncrement);
            }

            return startDate;
        },

        resetDatesToNow() {
            this.setDates(this.getRoundedCurrentMoment());
        },

        setDates(date) {
            this.getDatePickerData().setStartDate(date);
            this.getDatePickerData().setEndDate(date);
        },

        resetTimeselectValue(startDate) {
            if (startDate === undefined) {
                startDate = this.getStartDate();
            }
            // set en locale for correct parsing 12h time
            this.getContainer().find('.timeselect').val(startDate.locale('en').format(this.jsTimeFormat));
        },

        renderTimeInput() {
            // if daterangepicker still not created
            if (!this.getDatePickerData()) {
                let self = this;
                setTimeout(() => {
                    self.renderTimeInput();
                }, 50);

                return;
            }

            let inputmaskTimePlaceholder = '00:00';
            let inputmaskTimeFormat = this.time24Hour ? 'HH:MM' : 'hh:MM';
            if (this.timePickerSeconds) {
                inputmaskTimeFormat += ':ss';
                inputmaskTimePlaceholder += ':00';
            }
            if (!this.time24Hour) {
                inputmaskTimeFormat += ' TT';
                inputmaskTimePlaceholder += ' TM';
            }
            let timeInputTemplate = `${'<div class="timeselector">'
                + '<div class="form-group">'
                + '<div class="input-group">'
                + '<span class="input-group-text">Time</span>'
                + '<input type="text" class="form-control input-sm timeselect" placeholder="'}${inputmaskTimePlaceholder}" data-original-name="from" value="00:00">`
                + '</div>'
                + '</div>'
                + '</div>';

            let datePickerContainer = this.getContainer();
            $(timeInputTemplate).prependTo(datePickerContainer.find('.ranges'));

            let minTime = '00:00';
            let maxTime = '23:55';
            if (!this.allowTimeInPast && this.getStartDate().isSame(moment(), 'day')) {
                minTime = this.roundTime(moment()).locale('en'); // set en locale for correct parsing 12h time
                maxTime = this.roundTime(moment().endOf('day').subtract(5, 'minutes')).locale('en');

                if (minTime.isAfter(this.getStartDate())) {
                    this.setDates(minTime);
                }

                minTime = minTime.format(this.jsTimeFormat);
                maxTime = maxTime.format(this.jsTimeFormat);
            }

            this.resetTimeselectValue();

            datePickerContainer.find('.timeselect:not(.processed)')
                .addClass('processed')
                .timepicker({
                    step: this.timePickerIncrement,
                    timeFormat: this.phpTimeFormat,
                    minTime,
                    maxTime,
                }).inputmask({
                    alias: 'datetime',
                    inputFormat: inputmaskTimeFormat,
                    hourFormat: this.time24Hour ? 24 : 12,
                    placeholder: inputmaskTimePlaceholder,
                });

            // replace event listener. dont hide datepicker when mouse down on timepicker
            this.element.on('show.daterangepicker', (ev, picker) => {
                $(document).on('mousedown.daterangepicker', (e) => {
                    if (e.target.closest('.ui-timepicker-wrapper')) {
                        $(picker.element).trigger('click');
                    }
                });
            });

            datePickerContainer.find('.calendar-time').hide();

            // fix timeselect focus
            if ($('.ui-dialog').length > 0) {
                $.ui.dialog.prototype._focusTabbable = function () {
                };
            }
        },
    };

    let inputMaskLoaded = false;

    function loadInputmask(callback) {
        if (!inputMaskLoaded) {
            $.getScript('/js/development/jquery.inputmask.min.js', () => {
                inputMaskLoaded = true;
                callback();
            });
        } else {
            callback();
        }
    }

    $.fn.customdatetimepicker = function (daterangepickerConfigs, customOptions, callback) {
        this.each(function () {
            let el = $(this);
            if (el.data('customdatetimepicker')) {
                el.removeData('customdatetimepicker');
            }
            el.data('customdatetimepicker', new window.CustomDatetimePicker(el, daterangepickerConfigs, customOptions, callback));
        });
    };
});
