<template>
    <div class="splynx-wrapper empty-page">
        <slot />
        <x-global-notifications />
    </div>
</template>

<script>
import XGlobalNotifications from '@/components/base/XGlobalNotification';

export default {
    name: 'EmptyLayout',
    components: {
        XGlobalNotifications,
    },
};
</script>

<style lang="scss">
</style>
