import { PUSH_NOTIFICATION, REMOVE_NOTIFICATION, REMOVE_NOTIFICATIONS } from './mutation-types';

export default {
    [PUSH_NOTIFICATION](state, item) {
        state.notifications.push(item);
    },
    [REMOVE_NOTIFICATIONS](state) {
        state.notifications = [];
    },
    [REMOVE_NOTIFICATION](state, id) {
        let index = state.notifications.findIndex(((element) => element.id === id));
        if (index === -1) {
            return;
        }
        state.notifications.splice(index, 1);
    },
};
