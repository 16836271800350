import { isset } from '@/utils/functions';
import Bus from './bus';

export default {
    name: 'x-modal-wrapper',
    data() {
        return {
            modals: [],
        };
    },
    created() {
        Bus.on('new', (options) => {
            // Make every modal window unique
            if (this.getModal(options.name)) {
                return false;
            }
            let instance = {
                name: options.name,
                options: { ...options },
            };

            instance.options.props.name = instance.name;

            this.modals.push(instance);

            Bus.emit('opened', {
                name: options.name,
                instance,
            });
        });

        Bus.on('close', (data) => {
            let { name } = data;
            let modal = this.getModal(name);
            if (modal && isset(modal, ['options', 'onClose'])
                && typeof modal.options.onClose === 'function') {
                modal.options.onClose(data.data);
            }

            this.$modal.hide(name);
        });

        Bus.on('dismiss', (name) => {
            let modal = this.getModal(name);
            if (modal && isset(modal, ['options', 'onDismiss'])
                && typeof modal.options.onDismiss === 'function') {
                modal.options.onDismiss();
            }

            this.$modal.hide(name);
        });

        Bus.on('submit', (name) => {
            let modal = this.getModal(name);
            if (modal && isset(modal, ['options', 'onSubmit'])
                && typeof modal.options.onSubmit === 'function') {
                modal.options.onSubmit();
            }

            this.$modal.hide(name);
        });

        Bus.on('remove', (name) => {
            this.removeByName(name);
        });
    },
    methods: {
        removeByName(name) {
            this.modals = this.modals.filter((modal) => modal.name !== name);
        },
        getModal(name) {
            return this.modals.find((modal) => modal.name === name);
        },
    },
    render(h) {
        if (!this.modals.length) {
            return null;
        }

        let modals = this.modals.map((modal) => h(modal.options.component, {
            props: { ...modal.options.props },
        }));

        return h('div', {
            class: 'x-modal-wrapper',
        }, [modals]);
    },
};
