import stateToGetters from '@/apps/portal/store/helpers';
import {
    CHANGE_SERVICE_SELECT,
    DELETE_SELECTED_SERVICE,
    DELETE_SERVICE_SELECT,
    PAUSE_ALL_SERVICES,
    SET_ALL_SERVICES,
    SET_LOADED_SERVICES,
    SET_PAY_BY_OPTIONS,
    SET_PORTAL_TARIFF_FOR_CHANGE,
    SET_PORTAL_TARIFFS_FOR_CHANGE_LIST,
    SET_SELECTED_SERVICES,
    SET_SERVICES,
} from '@/apps/portal/store/mutation-types';
import { emptyObject } from '@/utils/functions';

const state = {
    services: null,
    totalMonthlyPrice: '',
    allServices: null,
    loadMore: null,
    selectedServices: [],
    selectedServicesPriceFormatted: 0,
    selectedServicesPrice: 0,
    tariffsForChangeList: {},
    tariffsForChangeDate: '',
    tariffForChange: {},
    canSeeOrderPage: false,
    showPayByForm: false,
    payByPoints: [],
    callbackData: {},
    canPauseAll: false,
    customerID: null,
    hasDiscount: false,
};

const getters = {
    ...stateToGetters(state),
};

const actions = {
    // Set customer services and monthly total price
    loadServices({ commit }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/services--load-services',
                method: 'GET',
                data: '',
                success: (response) => {
                    commit(SET_SERVICES, response);
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },

    // Get all services from current customer
    loadMoreServices({ commit }, loadMore) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/services--load-services',
                method: 'GET',
                data: { type: loadMore.type, start: loadMore.start },
                success: (response) => {
                    commit(SET_LOADED_SERVICES, response);
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },

    loadOrderPageData({ commit, state }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/order--load-order-page-data',
                method: 'GET',
                data: '',
                success: (response) => {
                    commit(SET_PAY_BY_OPTIONS, response);
                    commit(SET_ALL_SERVICES, response);
                    commit(SET_SELECTED_SERVICES, response);
                    state.totalMonthlyPrice = response.total_monthly;
                    state.customerID = response.customer_id;

                    for (let i = 0; i < response.selected_services.length; ++i) {
                        commit(CHANGE_SERVICE_SELECT, {
                            type: response.selected_services[i].type,
                            id: response.selected_services[i].id,
                        });
                    }
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },

    // Get all tariffs from change to current tariff
    loadTariffsForChangeList({ commit }, { item }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/services--load-tariffs-for-change',
                method: 'GET',
                data: { list: true, type: item.type, id: item.id },
                success: (response) => {
                    commit(SET_PORTAL_TARIFFS_FOR_CHANGE_LIST, response);
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },

    // Get tariff info from change
    loadTariffForChange({ commit }, {
        service_id, type, target_date, new_tariff_id,
    }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/services--get-tariff-changing-info',
                method: 'GET',
                data: {
                    service_id, type, target_date, new_tariff_id,
                },
                success: (response) => {
                    commit(SET_PORTAL_TARIFF_FOR_CHANGE, response);
                    if (response.errors) {
                        response.errors.forEach((elem) => {
                            show_error(elem.error, 4);
                        });
                    }
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },

    // Add service to selected service list
    addSelectedServiceTariff({ commit }, { item }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/order--add-selected-service',
                method: 'GET',
                data: { type: item.type, id: item.id },
                success: (response) => {
                    commit(SET_SELECTED_SERVICES, response.options);
                    commit(CHANGE_SERVICE_SELECT, {
                        type: item.type,
                        id: item.id,
                    });
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },

    // Delete service to selected service list
    deleteSelectedServiceTariff({ commit }, { item }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/order--delete-selected-service',
                method: 'GET',
                data: { type: item.type, id: item.id },
                success: (response) => {
                    commit(SET_SELECTED_SERVICES, response.options);
                    commit(DELETE_SERVICE_SELECT, {
                        type: item.type,
                        id: item.id,
                    });
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },

    // Add service to selected service list
    dropCart({ state }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/order--drop-cart',
                method: 'GET',
                success: () => {
                    state.selectedServices = [];
                    state.selectedServicesPriceFormatted = 0;
                    state.selectedServicesPrice = 0;
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },

    // Pause all customer active service
    pauseAllActiveServices({ dispatch }) {
        // Must pause all customer services and get new services array
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/services--stop-all-services',
                method: 'GET',
                data: '',
                success: (response) => {
                    if (response.result) {
                        dispatch('loadServices');
                        show_success(response.message, 4);
                        if (response.options?.is_need_to_reload) {
                            address_reload();
                        }
                    } else {
                        show_error(response.message[0], 4);
                    }
                    resolve();
                },
                error: (err) => {
                    reject(err);
                },
            });
        });
    },
    // Resume all customer active service
    resumeAllActiveServices({ dispatch }) {
        // Must pause all customer services and get new services array
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/services--resume-all-services',
                method: 'GET',
                data: '',
                success: (response) => {
                    if (response.result) {
                        dispatch('loadServices');
                        show_success(response.message, 4);
                        if (response.options?.is_need_to_reload) {
                            address_reload();
                        }
                    } else {
                        showErrors(response.message, 4);
                    }
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },

    // Pause active service
    pauseService({ dispatch }, { item }) {
        // Must pause current service and reload services
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/services--stop-service',
                method: 'GET',
                data: {
                    id: item.id,
                    serviceType: item.type,
                },
                success: (response) => {
                    if (response.result) {
                        dispatch('loadServices');
                        show_success(response.message, 4);
                        if (response.data.is_need_to_reload) {
                            address_reload();
                        }
                    }
                    if (!response.result && response.message) {
                        show_error(response.message, 4);
                    }
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },

    // Start service
    startService({ dispatch }, { item }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: '/portal/services/services--start-service',
                method: 'GET',
                data: {
                    id: item.id,
                    serviceType: item.type,
                },
                success: (response) => {
                    dispatch('loadServices');
                    if (response.result) {
                        show_success(response.message, 4);
                        if (response.options.is_need_to_reload) {
                            address_reload();
                        }
                    }
                    if (!response.result && response.message) {
                        show_error(response.message, 4);
                    }
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },

    // Cancel service
    cancelService({ dispatch }, { item }) {
        // FROM VISUAL AND DELETE THEN BE WORK
        dispatch('loadServices');
        console.log(dispatch, item);
    },

    // Change service
    changeService({ dispatch }, { tariff, changeTo, startDate }) {
        return new Promise((resolve, reject) => {
            let changeTariff = {};
            changeTariff.newTariffId = changeTo.id;
            changeTariff.newServicePrice = changeTo.price;
            changeTariff.targetDate = startDate;
            let url = `/portal/services/services--change-tariff?id=${tariff.id}&serviceType=${tariff.type}`;
            $.ajax({
                url,
                method: 'POST',
                data: {
                    ChangeTariff: changeTariff,
                },
                success: (response) => {
                    dispatch('loadServices');
                    if (response.result) {
                        show_success(response.message, 4);
                    }
                    if (!response.result && response.message) {
                        show_error(response.message, 4);
                        return false;
                    }
                    resolve(true);
                },
                error: (error) => {
                    show_error(error.message, 4);
                    reject(error);
                },
            });
        });
    },

    // Request to change current service
    requestToChange({ dispatch }, { item, changeTo, form }) {
        // Request to change current service
        // Item = info about tariff
        // ChangeTo = info about new tariff
        // Form info
        let url = `/portal/services/services--request-tariff-plan-change?serviceId=${item.id}&serviceType=${item.type}&targetDate=${form.date}`;
        if (changeTo.id !== undefined) {
            url += `&newTariffId=${changeTo.id}`;
        }

        let formData = new FormData();
        if (!emptyObject(changeTo)) {
            formData.append('ChangeTariff[newTariffId]', changeTo.id);
            formData.append('ChangeTariff[newServicePrice]', changeTo.price);
        }

        formData.append('TicketMessages[message]', isset(form, 'message') ? form.message : '');

        if (form.files) {
            formData.append('TicketMessages[files]', form.files);
        }
        return new Promise((resolve, reject) => {
            $.ajax({
                url,
                method: 'POST',
                data: formData,
                contentType: false,
                processData: false,
                success: (response) => {
                    dispatch('loadServices');
                    if (response.result) {
                        show_success(response.message, 4);
                        resolve(true);
                    }
                    if (!response.result && response.message) {
                        showErrors(response.message, 4);
                        resolve(false);
                    }
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },

    // Change password from current service
    changeServicePassword({ dispatch }, { item, password, passwordConfirm }) {
        return new Promise((resolve, reject) => {
            $.ajax({
                url: `/portal/services/services--change-password?id=${item.id}`,
                method: 'POST',
                data: {
                    password,
                    passwordConfirm,
                },
                success: (response) => {
                    dispatch('loadServices');
                    if (response.result) {
                        show_success(response.message, 4);
                    }
                    if (!response.result && response.message) {
                        show_error(response.message, 4);
                    }
                    resolve();
                },
                error: (error) => {
                    reject(error);
                },
            });
        });
    },
};

const mutations = {
    [SET_SERVICES](state, info) {
        state.services = info.items;
        state.totalMonthlyPrice = info.total;
        state.loadMore = info.loadMore;
        state.canSeeOrderPage = info.canSeeOrderPage;
        state.canPauseAll = info.canPauseAll;
        state.hasDiscount = info.has_discount;
    },
    [SET_SELECTED_SERVICES](state, info) {
        // When backend work
        state.selectedServices = info.selected_services;
        state.selectedServicesPriceFormatted = info.price_formatted;
        state.selectedServicesPrice = info.price;
    },
    [SET_ALL_SERVICES](state, info) {
        state.allServices = info.tariffs;
    },
    [SET_LOADED_SERVICES](state, info) {
        state.services = [].concat(state.services, info.items);
        state.loadMore = info.loadMore;
    },
    [CHANGE_SERVICE_SELECT](state, info) {
        if (!state.allServices) {
            return;
        }
        state.allServices[info.type].forEach((el) => {
            if (info.id === el.id) {
                el.select = true;
            }
        });
    },
    [DELETE_SERVICE_SELECT](state, info) {
        state.allServices[info.type].forEach((el) => {
            if (info.id === el.id) {
                el.select = false;
            }
        });
    },
    [SET_PORTAL_TARIFFS_FOR_CHANGE_LIST](state, info) {
        state.tariffsForChangeList = info.list;
        state.tariffsForChangeDate = info.date;
    },
    [SET_PORTAL_TARIFF_FOR_CHANGE](state, info) {
        state.tariffForChange = info.item;
    },
    // FROM VISUAL AND DELETE THEN BE WORK
    [PAUSE_ALL_SERVICES](state) {
        state.services.forEach((el) => {
            el.active = false;
        });
    },
    [DELETE_SELECTED_SERVICE](state, item) {
        state.selectedServices.forEach((el, index) => {
            if (el.id === item.id) {
                state.selectedServices.splice(index, 1);
            }
        });
    },
    [SET_PAY_BY_OPTIONS](state, info) {
        state.showPayByForm = info.show_pay_by_form;
        state.payByPoints = info.pay_by_points;
        state.callbackData = info.callback_data;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
};
