// Create code snippet popup
import { encode } from 'html-entities';
import uniqid from 'uniqid';

const TEXT_NODE = 3;
const ICON_PLUS = 'icon-ic_fluent_add_24_regular';
const ICON_DISMISS = 'icon-ic_fluent_dismiss_24_regular';
const ICON_CLIPBOARD = 'icon-ic_fluent_clipboard_code_24_regular';
const ICON_SAVE = 'icon-ic_fluent_save_24_regular';

const changeButtonTitle = (button, title) => {
    button.data('title', title);
};

const changeButtonText = (button, text) => {
    if (button.text()) {
        button.text(text);
    }
};

const enableEditMode = (id, state, t) => {
    const btnSelector = `.froala-${id} [data-cmd="insertCode"]`;
    const iconSelector = `.froala-${id} [data-cmd="insertCode"] i`;
    const codeInputSelector = `.froala-${id} .froala-paste-code-input`;
    const codeInputTitle = `.froala-${id} .froala-paste-code-title`;
    if (state) {
        $(iconSelector).removeClass(ICON_PLUS);
        $(iconSelector).addClass(ICON_SAVE);
        changeButtonTitle($(btnSelector), t('common', 'Save'));
        changeButtonText($(btnSelector), t('common', 'Save'));
        $(codeInputTitle).text(t('common', 'Edit'));
    } else {
        $(codeInputTitle).text(t('common', 'Pass the code below'));
        $(iconSelector).removeClass(ICON_SAVE);
        $(iconSelector).addClass(ICON_PLUS);
        $(codeInputSelector).val('');
        changeButtonTitle($(btnSelector), t('common', 'Paste'));
        changeButtonText($(btnSelector), t('common', 'Paste'));
    }
};

const initFroalaCustomPopup = (config, id) => {
    const showWithFocusin = (target = false) => new Promise((resolve, reject) => {
        const selector = `.froala-${id} .froala-paste-code-input`;
        const editor = $(`#${id}`).data('froala.editor');
        if (empty(editor)) {
            reject();
        }
        editor.codeSnippet.showPopup();
        let content = '';
        if (target) {
            target.parent().parent().contents().each(function () {
                if (this.nodeType === TEXT_NODE) {
                    content = `${content}\n${$(this).get(0).textContent}`;
                }
            });
        }
        // Focus on toolbar & popup textarea
        $(selector).attr('disabled', null);
        editor.accessibility.focusPopup(editor.popups.get('codeSnippet.insertPopup'));
        resolve(content.trim());
    });

    window.setCodeSnippetEvents = (currentEditor) => {
        const editor = $(`#${currentEditor}`).data('froala.editor');
        if (empty(editor)) {
            return false;
        }

        editor.$el.find('.froala-code-panel').remove();
        editor.$el.find('pre').each(function () {
            const newUniqueId = uniqid('code_snippet_');
            $(this).attr('id', newUniqueId);
            const panelElement = $(`<div contenteditable="false" class="froala-code-panel">
                     <div contenteditable="false" class="froala-code-btn edit">
                       <i contenteditable="false" class="icon-ic_fluent_edit_24_regular"></i>
                     </div>
                     <div contenteditable="false" class="froala-code-btn remove">
                       <i contenteditable="false" class="icon-ic_fluent_delete_24_regular"></i>
                     </div>
                   </div>`);
            panelElement.find('.froala-code-btn.edit').attr('id', `edit-${newUniqueId}`);
            panelElement.find('.froala-code-btn.remove').attr('id', `remove-${newUniqueId}`);
            $(this).append(panelElement);
        });

        const editHandler = function () {
            $(this).off('click');
            $(this).on('click', () => {
                showWithFocusin($(this)).then((content) => {
                    enableEditMode(id, true, window.t);
                    const codeInput = $(`.froala-${id} .froala-paste-code-input`);
                    codeInput.addClass('edit');
                    // Edit code snippet
                    codeInput.val(content);
                    $(`.froala-${id} pre#${$(this).attr('id').substring(5)}`).addClass('current-edit');
                });
            });
        };
        const removeHandler = function () {
            const editor = $(`#${id}`).data('froala.editor');
            // Remove code snippet
            $(this).off('click');
            $(this).on('click', () => {
                const brAfter = $(this).parent().parent()
                    .next();
                if (brAfter.prop('tagName') === 'BR') {
                    brAfter.remove();
                }
                $(this).parent().parent()
                    .remove();
                editor.undo.saveStep();
            });
        };

        editor.$el.find('.froala-code-btn.remove').each(removeHandler);
        editor.$el.find('.froala-code-btn.edit').each(editHandler);

        return true;
    };

    Object.assign($.FroalaEditor.POPUP_TEMPLATES, {
        [`${config.pluginName}.${config.popupName}`]: '[_BUTTONS_][_CUSTOM_LAYER_]',
    });

    let buttons = [];
    config.popupButtons.forEach((popup) => {
        Object.entries(popup).forEach(([key, value]) => {
            if (key === 'name') {
                buttons.push(value);
            }
        });
    });
    Object.assign($.FroalaEditor.DEFAULTS, {
        popupButtons: [...buttons, 'popupClose'],
    });

    $.FroalaEditor.PLUGINS[config.pluginName] = function (editor) {
        // Create custom popup.
        function initPopup() {
            // Load popup template.
            let template = $.FroalaEditor.POPUP_TEMPLATES[`${config.pluginName}.${config.popupName}`];
            if (typeof template == 'function') {
                template = template.apply(editor);
            }

            // Popup buttons.
            let popupButtons = '';
            let bottom = '<div class="fr-buttons code-snippet-popup-panel d-flex align-items-center justify-content-end my-8 mt-4 px-12">';

            // Create the list of buttons.
            if (editor.opts.popupButtons.length > 1) {
                popupButtons += '<div class="fr-buttons code-snippet-popup-panel d-flex align-items-center">';
                popupButtons += `<label class="froala-paste-code-title flex-grow-1 m-8">${window.t('common', 'Pass the code below')}</label>`;
                const buttons = $(editor.button.buildList(editor.opts.popupButtons)).wrap();
                popupButtons += '<div class="d-flex align-items-center">';
                buttons.each(function () {
                    const dataCmd = $(this).attr('data-cmd');
                    const buttonName = editor.opts.popupButtons.find((buttonName) => buttonName === dataCmd);
                    if (buttonName) {
                        const thisConfig = config.popupButtons.find((item) => item.name === buttonName && !!item.text);
                        if (thisConfig) {
                            $(this).css('width', 'auto');
                            $(this).removeClass('fr-btn');
                            $(this).addClass('btn btn-sm btn-outline-secondary');
                            $(this).text(thisConfig.text);
                        }
                    }
                    const isBottom = config.popupButtons.find((item) => item.name === buttonName && item.isBottom);
                    if (isBottom) {
                        bottom += $(this).prop('outerHTML');
                    } else {
                        popupButtons += $(this).prop('outerHTML');
                    }
                    bottom += '</div>';
                });
                popupButtons += '</div></div>';
            }

            // Load popup template.
            template = {
                buttons: popupButtons,
                custom_layer: config.popupTemplate + bottom,
            };

            // Create popup.
            return editor.popups.create(`${config.pluginName}.${config.popupName}`, template);
        }

        // Show the popup
        editor.popups.onShow(`${config.pluginName}.${config.popupName}`, config.onShow);
        function showPopup() {
            let $popup = editor.popups.get(`${config.pluginName}.${config.popupName}`);
            if (!$popup) {
                $popup = initPopup();
            }
            editor.popups.setContainer(`${config.pluginName}.${config.popupName}`, editor.$tb);
            const $btn = editor.$tb.find(`.fr-command[data-cmd="${config.pluginName}"]`);
            const left = ($btn.offset().left + $btn.outerWidth() / 2) - 100;
            const top = $btn.offset().top + (editor.opts.toolbarBottom ? 10 : $btn.outerHeight() - 10);
            // $popup.find('textarea').css('max-width', `${editor.$box.width() / 1.2}px`);
            $popup.find('.fr-arrow').css('margin-left', '95px');
            editor.popups.show(`${config.pluginName}.${config.popupName}`, left, top, $btn.outerHeight());
        }

        // Hide the custom popup.
        editor.popups.onHide(`${config.pluginName}.${config.popupName}`, config.onHide);
        function hidePopup() {
            editor.popups.hide(`${config.pluginName}.${config.popupName}`);
        }

        editor.events.on('initialized', () => { config.onInit.apply(editor); }, true);
        editor.events.on('commands.undo', () => { config.onUndo.apply(editor); }, true);
        editor.events.on('commands.redo', () => { config.onUndo.apply(editor); }, true);
        // Methods visible outside the plugin.
        return {
            showPopup,
            hidePopup,
        };
    };

    // Define an icon and command for the button that opens the custom popup.
    $.FroalaEditor.DefineIcon(config.iconName, { NAME: config.iconClass });
    $.FroalaEditor.RegisterCommand(config.pluginName, {
        title: config.title,
        icon: config.iconName,
        undo: false,
        focus: true,
        popup: true,
        refreshAfterCallback: true,
        // Buttons which are included in the editor toolbar should have the plugin property set.
        plugin: config.pluginName,
        async callback() {
            this.selection.save();
            if (!this.popups.isVisible(`${config.pluginName}.${config.popupName}`)) {
                showWithFocusin();
            } else {
                if (this.$el.find('.fr-marker')) {
                    this.events.disableBlur();
                }
                this.popups.hide(`${config.pluginName}.${config.popupName}`);
            }
        },
    });

    // Define custom popup close button icon and command.
    $.FroalaEditor.DefineIcon('popupClose', { NAME: config.closeIconClass });
    $.FroalaEditor.RegisterCommand('popupClose', {
        title: config.closeTitle,
        undo: false,
        focus: true,
        callback() {
            this[config.pluginName].hidePopup(config.onHide);
        },
    });

    // Define popup buttons
    const generateButton = (title, name, iconName, iconClass, callback) => {
        $.FroalaEditor.DefineIcon(iconName, { NAME: iconClass });
        $.FroalaEditor.RegisterCommand(name, {
            title,
            icon: iconName,
            undo: false,
            focus: true,
            callback() {
                callback.apply(this);
            },
        });
    };

    config.popupButtons.forEach((popup) => {
        generateButton(popup.title, popup.name, popup.iconName, popup.iconClass, popup.onClick);
    });
};

const init = (instance) => {
    let codeContainer = null;
    initFroalaCustomPopup({
        popupName: 'insertPopup',
        title: window.t('common', 'Insert code snippet'),
        iconName: 'insertCodeSnippet',
        iconClass: ICON_CLIPBOARD,
        closeTitle: window.t('common', 'Close'),
        closeIconClass: ICON_DISMISS,
        popupTemplate: `
                    <div class="input-wrap">
                        <textarea class="m-8 mb-0 froala-paste-code-input" rows="10"></textarea>
                    </div>
                `,
        pluginName: 'codeSnippet',
        onHide() {
            enableEditMode(instance.id, false, window.t);
            $(`.froala-${instance.id} .current-edit`).removeClass('current-edit');
            const codeInputSelector = `.froala-${instance.id} .froala-paste-code-input`;
            $(codeInputSelector).removeClass('edit');
            $(codeInputSelector).val('');
            window.setCodeSnippetEvents(instance.id);
        },
        onShow() {
            const codeInputSelector = `.froala-${instance.id} .froala-paste-code-input`;
            if ($(codeInputSelector).hasClass('edit')) {
                enableEditMode(instance.id, true, window.t);
            } else {
                enableEditMode(instance.id, false, window.t);
            }
        },
        onUndo() {
            window.setCodeSnippetEvents(instance.id);
        },
        onInit() {
            const getCodeFromString = (input) => {
                const codeTagRegex = /<code(.|\n)*?<\/code>/gi;
                if (codeTagRegex.test(input)) {
                    return input.match(codeTagRegex).map((item) => item.replace(/<(?!br\s*\/?)[^>]+>/g, ''));
                }
                const regex = /<pre(.|\n)*?<\/pre>/gi;
                if (regex.test(input)) {
                    return input.match(regex).map((item) => item.replace(/<(?!br\s*\/?)[^>]+>/g, ''));
                }
                return [];
            };
            const getCodeSnippetsFromString = (input) => {
                const regex = /<pre class="code-snippet">(.|\n)*?<\/pre>/gi;
                if (regex.test(input)) {
                    return input.match(regex);
                }
                return [];
            };
            const snippets = getCodeSnippetsFromString(this._original_html);
            const filterPasteContent = (paste) => {
                let code = getCodeFromString(paste);
                if (!code.length) {
                    return paste;
                }
                this.selection.save();
                return `<pre contenteditable="false" class="code-snippet">${code.join('')}</pre>`;
            };
            $(`.froala-${instance.id} pre`).each(function (index) {
                let snippet = snippets[index];
                if (typeof snippet === 'string') {
                    snippet = snippet.substring('<pre class="code-snippet">'.length);
                    snippet = snippet.substring(0, snippet.length - '</pre>'.length);
                }
                $(this).html(document.createTextNode(snippet));
                $(this).attr('contenteditable', 'false');
            });
            window.setCodeSnippetEvents(instance.id);
            // Event handlers
            this.events.on('paste.afterCleanup', (clipboardHtml) => filterPasteContent(clipboardHtml), true);
            this.events.on('html.set', () => {
                window.setCodeSnippetEvents(instance.id);
            });
            this.events.on('contentChanged', () => {
                window.setCodeSnippetEvents(instance.id);
            }, true);
        },
        popupButtons: [
            {
                name: 'insertCode',
                isBottom: true,
                text: window.t('common', 'Paste'),
                title: window.t('common', 'Insert Code'),
                iconName: 'insert',
                iconClass: ICON_PLUS,
                async onClick() {
                    this.selection.restore();
                    const codeInput = $(`.froala-${instance.id} .froala-paste-code-input`);
                    // Validation
                    if (codeInput.val().trim() === '') {
                        return false;
                    }

                    const uniqueId = uniqid('code_snippet_');
                    codeContainer = $(`<pre class="code-snippet" id="${uniqueId}" contenteditable="false"></pre>`);

                    const createPanel = () => {
                        const removeButton = $(`<div contenteditable="false" class="froala-code-btn edit" id="edit-${codeContainer.attr('id')}">
                                                        <i class="icon-ic_fluent_edit_24_regular"></i>
                                                    </div>`);
                        const editButton = $(`<div contenteditable="false" class="froala-code-btn remove" id="remove-${codeContainer.attr('id')}">
                                                        <i class="icon-ic_fluent_delete_24_regular"></i>
                                                  </div>`);
                        const panel = $('<div contenteditable="false" class="froala-code-panel"></div>');
                        panel.append(removeButton);
                        panel.append(editButton);
                        return panel;
                    };

                    // Edit Handle
                    if (codeInput.hasClass('edit')) {
                        const encoded = encode(codeInput.val());
                        const liveContainer = $(`.froala-${instance.id} .current-edit`);
                        liveContainer.html('');
                        liveContainer.append(encoded.replace(/ /g, '&nbsp;'));
                        liveContainer.append(createPanel());
                        liveContainer.attr('id', codeContainer.attr('id'));

                        if (liveContainer.hasClass('current-edit')) {
                            liveContainer.removeClass('current-edit');
                        }

                        this.codeSnippet.hidePopup();
                        this.undo.saveStep();

                        window.setCodeSnippetEvents(instance.id);
                        return false;
                    }

                    // Get textarea content
                    codeContainer.text(codeInput.val());

                    // Insert Code
                    codeContainer.html(codeContainer.html().replace(/ /g, '&nbsp;'));
                    this.selection.save();
                    this.html.insert(codeContainer.get(0).outerHTML);
                    this.html.insert('<div><br></div>');

                    // Init code buttons
                    const liveContainer = $(`#${codeContainer.attr('id')}`);
                    liveContainer.append(createPanel());

                    // Reset textarea
                    codeInput.val('');
                    this.codeSnippet.hidePopup();

                    window.setCodeSnippetEvents(instance.id);
                    this.undo.saveStep();
                    this.accessibility.focusEditor();
                },
            },
        ],
    }, instance.id);
};

const activeEditorSnippetPlugin = {
    id: null,
    $t: null,
};
window.activeEditorSnippetPlugin = new Proxy(activeEditorSnippetPlugin, {
    set(object, property, id) {
        init({ $t: window.t, id });
        object.id = id;
        object.$t = window.t;
        return true;
    },
});

export default init;
