import { isset } from '@/utils/functions';

let codeCache = {};
let pagesPromises = {};

/**
 * Check is this portal or admin site
 * @returns {boolean}
 */
function isPortal() {
    return window.location.pathname.slice(0, 7) === '/portal';
}

function getCodeUrl() {
    if (isPortal()) {
        return '/portal/add-on--get-code';
    }

    return '/admin/config/add-on--get-code';
}

export default function loadAddonCode(page, params) {
    if (isset(pagesPromises, page)) {
        return pagesPromises[page];
    }

    pagesPromises[page] = new Promise((resolve, reject) => {
        if (isset(codeCache, page)) {
            delete pagesPromises[page];
            resolve(codeCache[page]);
            return;
        }

        let preparedPage = page.replace(/\\\\/g, '\\');
        let data = {
            before: {
                page: preparedPage,
                params,
                location: 'before_content',
            },
            after: {
                page: preparedPage,
                params,
                location: 'after_content',
            },
        };

        $.ajax({
            url: getCodeUrl(),
            method: 'POST',
            data,
            success: (response) => {
                if (isset(response, 'error')) {
                    delete pagesPromises[page];
                    reject(response);
                } else {
                    let codes = {
                        before: isset(response, 'before_code') ? response.before_code : '',
                        after: isset(response, 'after_code') ? response.after_code : '',
                    };
                    delete pagesPromises[page];
                    codeCache[page] = codes;
                    resolve(codes);
                }
            },
            error: (err) => {
                delete pagesPromises[page];
                reject(err);
            },
        });
    });

    return pagesPromises[page];
}
