import { emptyObject, interceptError } from '@/utils/functions';

const XErrorPlugin = {};

XErrorPlugin.install = (Vue) => {
    Vue.prototype.setErrorState = function (newState) {
        if (newState !== null && typeof newState !== 'undefined') {
            const serverError = newState.code >= 500 || newState.html?.code >= 500 || false;
            // https://jira-splynx.atlassian.net/browse/SPL-12127
            if (serverError && process.env.NODE_ENV === 'production') {
                return false;
            }
            window.closeAllVueDialogs();
            window.closeAllDialogs();
            this.$store.dispatch('setErrorState', newState);
        }
    };
    Vue.prototype.initAjaxInterceptors = function () {
        if (window.error_information && !emptyObject(window.error_information)) {
            this.$store.dispatch('setErrorState', window.error_information);
            return false;
        }
        // AJAX errors global processing
        $(document).ajaxError((event, xhr) => {
            interceptError(
                xhr,
                (response) => {
                    this.setErrorState(response);
                },
            );

            if (xhr.status === 401) {
                window.address_reload(false, true);
                return;
            }

            if (xhr.status === 403) {
                this.setErrorState({ code: 403, error: xhr.responseJSON.error });
                return;
            }

            if (xhr.status === 0) {
                return;
            }

            if (xhr.status === 200) {
                return;
            }

            if (!window.showAjaxErrors) {
                return;
            }

            let res = xhr.responseJSON;
            let message = xhr.statusText;
            if (typeof res != 'undefined' && res.error != null && typeof res.error != 'undefined') {
                message = `Server error: ${res.error}`;
                show_error(message, 5);
            }
        });

        $(document).ready(() => {
            $.ajaxSetup({
                beforeSend(jqXHR) {
                    jqXHR.setRequestHeader('Accept', 'application/json');
                },
            });
        });

        if (process.env.NODE_ENV === 'test') {
            Vue.prototype.initAjaxInterceptors = jest.fn();
        }
    };
};

export default XErrorPlugin;
