// This hack need for back compability with old client handlers

window.last_dialog_id = {
    close() {
        closeLastDialog();
    },
};
window.last_addon_dialog_id = {
    close() {
        window.closeLastAddonDialog();
    },
};
window.splynxDialogs = [];
window.splynxAddonDialogs = [];
window.password_window_id = 0;
window.is_animate = false;
window.decimals_separator = '.';
window.globalEditor = null;
window.tributeAttached = false;
window.disableEditorReinit = false;
window.mentionsList = null;
window.mentionsNeedsUpdating = false;
window.getMentionsIsRun = false;
window.vueACSComponentsMixins = {
    methods: {
        humanizeACSAttributeName(key) {
            if (empty(key)) {
                return '';
            }
            let word = key.replace(/^(X_(.*)_)/, '');
            return humanizeString(word);
        },
    },
};

window.vueHelpersMixins = {
    methods: {
        empty(value) {
            return empty(value);
        },
    },
};

window.load_dt_language = function (lng, localeSettings) {
    $.ajax({
        dataType: 'json',
        url: `/js/locale/datatable/${lng}.json`,
        success(result) {
            $.extend(result, localeSettings);
            $.extend($.fn.DataTable.defaults, {
                language: result,
            });
        },
    });
};

window.time = function () {
    return Math.round(new Date().getTime() / 1000);
};

window.downloadURL = function (url) {
    if (window.$idown) {
        window.$idown.attr('src', url);
    } else {
        window.$idown = $('<iframe>', { id: 'idown', src: url }).hide().appendTo('body');
    }
};

window.download_iframe = null;

window.download_by_iframe = function (url, isAsync = false) {
    const NOT_FOUND = 'Not found!';
    const download = () => {
        if (window.download_iframe) {
            window.download_iframe.attr('src', url);
            return false;
        }
        window.download_iframe = $('<iframe>', {
            id: 'idown',
            src: url,
        }).hide().appendTo('body');
    };

    if (isAsync) {
        $.ajax({
            type: 'GET',
            url,
            success(response) {
                if (response === NOT_FOUND) {
                    show_error(response, 4);
                } else {
                    download();
                }
            },
        });

        return false;
    }

    download();
};

window.show_error = function (msg, timeout, to_manager, ago) {
    show_notify('error', msg, timeout, to_manager, null, null, ago);
};

window.showErrors = function (errors) {
    errors?.forEach((error) => {
        window.show_error(error, 5);
    });
};

window.show_message = function (msg, timeout, to_manager, ago) {
    show_notify('info', msg, timeout, to_manager, null, null, ago);
};

window.show_success = function (msg, timeout, to_manager, ago) {
    show_notify('success', msg, timeout, to_manager, null, null, ago);
};

window.show_warning = function (msg, timeout, to_manager, ago) {
    show_notify('warning', msg, timeout, to_manager, null, null, ago);
};

window.show_information = function (msg, timeout, to_manager, ago) {
    show_notify('info', msg, timeout, to_manager, null, null, ago);
};

window.show_simple_notify = function (message, timeout) {
    window.xApp.$store.dispatch('global_notifications_store/showSimpleNotify', { message, timeout });
};

window.getNotyClasses = function (type) {
    switch (type) {
        case 'success':
            return { colorClass: 'text-success', iconClass: 'fa-check' };
        case 'info':
            return { colorClass: '', iconClass: 'fa-info' };
        case 'error':
            return { colorClass: 'text-light', iconClass: 'fa-ban' };
        case 'message':
            return { colorClass: '', iconClass: 'fa-info' };
        case 'warning':
            return { colorClass: 'text-warning', iconClass: 'fa-exclamation-triangle' };
        default:
            return { colorClass: '', iconClass: 'fa-info' };
    }
};

window.close_notify = function () {
    window.xApp.$store.dispatch('global_notifications_store/cleanNotifications');
};

window.show_notify = function (type, message, timeout, to_manager, action_button, close_button) {
    if (!window.xApp) {
        let { iconClass, colorClass } = window.getNotyClasses(type);
        noty({
            text: `<div class="activity-item"><i class="fa ${iconClass} ${colorClass}"></i> <div class="activity ${colorClass}">${message}<span>&nbsp;</span></div> </div>`,
            layout: 'topRight',
            timeout: timeout * 1000,
            type,
        });
        return false;
    }
    if (typeof message !== 'string' && message?.length) {
        message?.forEach((content) => {
            window.show_notify(type, content, timeout, to_manager, action_button, close_button);
        });
        return true;
    }
    window.xApp.$store.dispatch('global_notifications_store/showNotify', {
        type,
        message,
        timeout,
        to_manager,
        action_button,
        close_button,
    });
};

window.isValidEmail = function (str) {
    return (str.indexOf('.') > 2) && (str.indexOf('@') > 0);
};

window.isValidMobile = function (str) {
    if (str.length != 13) return false;
    if (str.charAt(0) != '+') return false;
    return true;
};

window.isValidPhone = function (str) {
    if (str.length == 0) return true;
    if (str.length != 7) return false;
    return true;
};

// Select with class 'select2' ake Select2 objects
window.makeSelect2 = function ($scope) {
    let elements;
    if ($($scope).length) {
        elements = $($scope).find('select.select2');
    } else {
        elements = $('select.select2');
    }

    elements.each(function () {
        if ($(this).data('select2') === undefined) {
            $(this).select2({ width: 'resolve' });
        }

        if (!empty($(this).data('select2'))) {
            return;
        }

        $(window).on('resize', () => {
            let selects = document.querySelectorAll('.select2.select2-container');
            selects.forEach((select) => {
                select.removeAttribute('style');
            });
        });
    });
    window.setGlobalSelects(elements);

    // additional make textarea autosize
    window.autosize($('textarea'));
};

window.makeMultileSelect = function ($scope, options) {
    if (typeof options === 'undefined') {
        options = {};
    }

    let elements;
    if ($($scope).length) {
        elements = $($scope).find("select[multiple='multiple']");
    } else {
        elements = $("select[multiple='multiple']");
    }

    elements.each(function () {
        if (!empty($(this).data('multipleSelect'))) {
            // Multiple select already initialized
            return;
        }

        let htmlElement = this;
        let displaySelectedValues = false;
        let countSelected = 5;
        let elWidth = htmlElement.style.width;

        if ($('optgroup', htmlElement).length != undefined && $('optgroup', htmlElement).length > 0) {
            displaySelectedValues = true;
            countSelected = 0;
        }

        if ($(this).attr('data-check-all')) {
            let selected = [];
            $(this).find('option').each(function () {
                selected.push($(this).val());
            });

            $(this).val(selected);
        }

        if (elWidth == '') {
            elWidth = '100%';
        }

        let containerFromMultipleSelect = '';
        if ($('.ui-dialog').length) {
            containerFromMultipleSelect = '.splynx-isp';
        }

        let multipleOptions = {
            displayValues: displaySelectedValues,
            minimumCountSelected: countSelected,
            width: elWidth,
            container: containerFromMultipleSelect,
            filter: true,
            onOpen() {
                window.fixDropDownPosition(htmlElement);
                $(window).on('scroll', () => {
                    window.fixDropDownPosition(htmlElement);
                });

                let multipleSelect = $(htmlElement).data('multipleSelect');
                let modal = $(htmlElement).closest('.ui-dialog');
                let contentWrapper;
                let dropElement;
                let modalHeight;
                let widthDropElement;
                let heightDropElement;
                let cssParams = {};

                if (modal.length > 0) {
                    $('.splynx-dialog-content').css({
                        overflow: 'hidden',
                    });

                    contentWrapper = $(htmlElement).closest('.ui-dialog-content');
                    dropElement = multipleSelect.$drop;
                    modalHeight = $(contentWrapper).height();
                    widthDropElement = $(htmlElement).outerWidth();
                    dropElement.css({ bottom: 'unset' });
                    heightDropElement = dropElement.height();

                    cssParams = {
                        width: widthDropElement,
                        position: 'fixed',
                        bottom: 'unset',
                    };

                    let msParentTopOffset = $(htmlElement).parent().find('.ms-parent').offset().top;
                    let scrollTop = $(window).scrollTop();
                    let msParentHeight = $(htmlElement).parent().find('.ms-parent').height();

                    if (dropElement.hasClass('top')) {
                        // Position === top
                        cssParams.top = msParentTopOffset - scrollTop - heightDropElement;
                    } else {
                        // Position === bottom
                        cssParams.top = msParentTopOffset - scrollTop + msParentHeight;
                    }

                    if (heightDropElement > 0) {
                        cssParams.height = heightDropElement;
                    }

                    dropElement.css(cssParams);

                    // Calculate current position for multipleSelect
                    let top = $(dropElement).offset().top - $(contentWrapper).offset().top + $(contentWrapper).scrollTop();
                    if (top + $(dropElement).find('ul').height() > modalHeight) {
                        $(contentWrapper).scrollTop(top);
                    }

                    // Fix multipleSelect height
                    $(dropElement).find('ul').css('max-height', `${this.maxHeight}px`);

                    $(document).ready(() => {
                        $(document).off('focusin');
                        $('.ui-dialog ~ .ms-drop').css('height', 'auto');
                        $('.ms-drop .input-ms-search').each(function () {
                            if ($(this).is(':visible')) {
                                $(this).get(0).focus();
                            }
                        });
                    });
                }

                $(htmlElement).next('.ms-parent').find('.ms-choice').addClass('multipleselect-opened');

                $(window).on('resize', () => {
                    let topOffset = $('.ui-dialog-content .ms-parent').offset()?.top;
                    if (topOffset === null) {
                        topOffset = 0;
                    }
                    let top = topOffset - $(window).scrollTop() + $('.ms-parent').height();
                    if (top) {
                        $(dropElement).css('top', top);
                    }
                    $(dropElement).css('left', $(htmlElement).offset().left);
                    $(dropElement).css('width', $(contentWrapper).find('.ms-choice').width());
                });
            },
            onClose() {
                $(window).off('scroll');
                $('.splynx-dialog-content').css({
                    'overflow-x': 'hidden',
                    'overflow-y': 'auto',
                });
                $(htmlElement).next('.ms-parent').find('.ms-choice').removeClass('multipleselect-opened');
            },
            onClick() {
                let event = document.createEvent('CustomEvent');
                event.initEvent('change-multiple-select', true, true);
                event.eventName = 'change-multiple-select';
                event.multipleSelectSelectedValues = $(htmlElement).val();
                htmlElement.dispatchEvent(event);
            },
            ...options,
        };

        $(this).multipleSelect(multipleOptions);

        $(this).next('.ms-parent').find('.ms-search input').addClass('input-ms-search');

        // if there is a read-only attribute, we disable the selection
        if ($(this).attr('readonly')) {
            $(this).multipleSelect('disable');
        }
    });

    window.setGlobalSelects(elements);
};

window.setGlobalSelects = (elements) => {
    if (window.allSelects && window.allSelects.length) {
        window.allSelects.push(...elements);
    } else {
        window.allSelects = [...elements];
    }
};

window.makeDatepicker = function ($scope) {
    let elements;
    if ($($scope).length) {
        elements = $($scope).find('input.datepicker_auto');
    } else {
        elements = $('input.datepicker_auto');
    }

    elements.daterangepicker(window.daterangepicker_default_config);

    if ($($scope).length) {
        $($scope).find('input.daterangepicker_single_auto').daterangepicker(window.daterangepicker_single_default_config);
        $($scope).find('input.daterangepicker_single_datetime_auto').daterangepicker(window.daterangepicker_single_datetime_default_config);
    } else {
        $('input.daterangepicker_single_auto').daterangepicker(window.daterangepicker_single_default_config);
        $('input.daterangepicker_single_datetime_auto').daterangepicker(window.daterangepicker_single_datetime_default_config);
    }
};

window.makeDecimalInput = function ($scope) {
    if ($($scope).length) {
        $($scope).find('input.decimal').on('keyup', function () {
            $($scope).find('input.decimal').number(true, Number(window.spl_config.numberConfig.decimal.digits), window.decimals_separator, '');
            $($scope).find('input.decimal_finance').number(true, Number(window.spl_config.numberConfig.decimal_finance.digits), window.decimals_separator, '');
            window.fixNumber(this);
        });
        $($scope).find('input.tax_input').number(true, window.tax_decimals, window.decimals_separator, '');
    } else {
        $('input.decimal').number(true, Number(window.spl_config.numberConfig.decimal.digits), window.decimals_separator, '');
        $('input.decimal_finance').number(true, Number(window.spl_config.numberConfig.decimal_finance.digits), window.decimals_separator, '');
        $('input.decimal').on('keyup', function () {
            window.fixNumber(this);
        });
        $('input.tax_input').number(true, window.tax_decimals, window.decimals_separator, '');
    }
};

window.small_dialog = function (title, content, o, afterOpenCallback, afterCloseCallback) {
    let self = this;
    let defaults = {
        class: 'splynx-msg',
        dialog: {
            height: '250',
            width: '450',
            title,
            autoOpen: false,
            resizable: false,
            buttons: {
                Ok() {
                    $(this).dialog('close');
                },
            },
        },
    };
    this.opts = jQuery.extend(true, defaults, o, {
        dialog: {
            autoOpen: false,
            close() {
                self.close();
                if (typeof afterCloseCallback == 'function') {
                    afterCloseCallback();
                }
            },
            dialogClass: 'spl-dialog',
        },
    });

    if (o && o.dialog && o.dialog.buttons && typeof (o.dialog.buttons) == 'object') {
        this.opts.dialog.buttons = o.dialog.buttons;
    }

    this.dialog = jQuery('<div />').addClass(this.opts.class).dialog(this.opts.dialog);
    this.content = jQuery('<div class="splynx-dialog-content" />').appendTo(this.dialog);
    this.option = function (name, value) {
        return this.dialog.dialog('option', name, value);
    };

    let closeButton = $('.ui-dialog-titlebar-close');
    closeButton.empty();
    closeButton.append('<i class="icon-ic_fluent_dismiss_24_regular"></i>');

    this.open = function () {
        this.dialog.attr('unselectable', 'on').dialog('open');
        $('body').append($('<div>').addClass('ui-widget-overlay ui-front').css('z-index', '90'));
        this.content.html(content);

        if (typeof afterOpenCallback == 'function') {
            afterOpenCallback();
        }

        return this;
    };
    this.close = function () {
        this.dialog.dialog('destroy').remove();
        $('.ui-widget-overlay').remove();
        if (typeof afterCloseCallback == 'function') {
            afterCloseCallback();
        }
    };

    if (typeof afterCloseCallback == 'function') {
        closeButton.on('click', () => {
            afterCloseCallback();
        });
    }
};

/**
 * Check if form has changed
 * @param container
 * @returns {boolean}
 */
window.isHaveUnsavedData = function (container) {
    let forms;
    if ($(container).find('form').length <= 0) {
        forms = $(container).closest('form[data-form-changed]:visible:not([data-silent-form])');
    } else {
        forms = $(container).find('form[data-form-changed]:visible:not([data-silent-form])');
    }

    if (forms.length > 0) {
        return true;
    }

    return false;
};

/**
 * Forget unsaved data
 * @param container
 */
window.forgetUnsavedData = function (container) {
    let forms;
    if ($(container).find('form').length <= 0) {
        forms = $(container).closest('form[data-form-changed]:visible:not([data-silent-form])');
    } else {
        forms = $(container).find('form[data-form-changed]:visible:not([data-silent-form])');
    }

    forms.removeAttr('data-form-changed');
};

window.showUnsavedDataDialog = function (contentContainer, confirm, reject, text) {
    if (text === undefined) {
        text = t('common', 'Are you sure that you want to leave page? All unsaved data will be lost!');
    }

    window.vue_confirm_dialog(text, () => {
        forgetUnsavedData($(contentContainer));
        confirm();
    }, reject);
};

window.checkUnsavedDataBeforeLeavePage = function (confirm, reject, contentContainer, text) {
    if (contentContainer === undefined) {
        contentContainer = '#content, .splynx-dialog-content, .vue-dialog-body';
    }

    if (isHaveUnsavedData(contentContainer)) {
        window.showUnsavedDataDialog(contentContainer, confirm, reject, text);
    } else {
        confirm();
    }
};

window.checkUnsavedDataBeforeLeaveTab = function (container, confirm, reject) {
    checkUnsavedDataBeforeLeavePage(confirm, reject, container, t('common', 'Are you sure that you want to leave tab? All unsaved data will be lost!'));
};

window.checkUnsavedDataBeforeCloseDialog = function (container, confirm, reject) {
    if (isHaveUnsavedData(container)) {
        window.vue_confirm_dialog(t('common', 'Are you sure that you want to close dialog? All unsaved data will be lost!'), confirm, reject);
        return true;
    }
    return false;
};

window.vue_confirm_dialog = function (text, confirm, reject) {
    let rejectFunction = function () {
        if (typeof reject === 'function') {
            reject();
        }
    };

    showModal('confirm-forget-unsaved-data-modal', {
        onSubmit: confirm,
        onDismiss: rejectFunction,
        text,
    });
};

window.splynx_dialog = function (url, o, data) {
    let self = this;
    let defaults = {
        class: 'splynx-msg',
        dialog: {
            height: '250',
            title: 'Loading...',
            autoOpen: false,
            resizable: true,
            dialogClass: 'spl-dialog',
        },
    };
    this.opts = $.extend(true, defaults, o, {
        dialog: {
            autoOpen: false,
        },
    });
    if (o && o.dialog && o.dialog.buttons && typeof (o.dialog.buttons) == 'object') {
        this.opts.dialog.buttons = o.dialog.buttons;
    }
    this.dialog = $('<div />').addClass(this.opts.class).dialog(this.opts.dialog);
    this.content = $('<div class="splynx-dialog-content" />').appendTo(this.dialog);
    this.option = function (name, value) {
        return this.dialog.dialog('option', name, value);
    };

    $(this.dialog).on('dialogopen', function () {
    // Disable scrolling
        $('body').addClass('modal-open');

        let dialogWindow = $(this.dialog);

        $(window).resize(function () {
            let horizontalCenter = Math.floor(window.innerWidth / 2);
            let verticalCenter = Math.floor(window.innerHeight / 2);
            let modalHalfWidth = $('.ui-dialog').outerWidth() / 2;
            let modalHalfHeight = $('.ui-dialog').outerHeight() / 2;

            $('.ui-dialog').css({ top: verticalCenter - modalHalfHeight, left: horizontalCenter - modalHalfWidth });

            if ($(window).width() <= 1024) {
                let panelWidth = dialogWindow.parent().width();
                let contentWidthOffset = $(this).outerWidth() - $(this).width();

                $('.splynx-msg').css('minHeight', '300px');
                $(this.dialog).width(panelWidth - contentWidthOffset);
            }
        });
    });

    const closeFloatElementsInside = () => {
        if (window.allSelects) {
            window.allSelects.forEach((select) => {
                if ($(select).length) {
                    if ($(select).data('select2')) {
                        $(select).select2('close');
                    } else {
                        $(document).click();
                    }
                }
            });
        }
    };

    this.close_confirmed = false;
    $(this.dialog).on('dialogbeforeclose', () => {
        if (typeof window.beforeCloseCallback == 'function') {
            if (!window.beforeCloseCallback(self.dialog)) {
                return false;
            }
        }

        let close = self.close_confirmed || !checkUnsavedDataBeforeCloseDialog(self.dialog, () => {
            self.close_confirmed = true;
            self.dialog.dialog('close');
        });

        self.close_confirmed = false;

        return close;
    });

    $(this.dialog).on('dialogclose', function () {
    // Enable scrolling
        if (self.enableScrollingAfterClose) {
            $('body').removeClass('modal-open');
        }

        window.removeDatepicker(this.dialog);

        let wP = $('.webui-popover');
        // wP.webuiPopover('hideAll');
        wP.detach();
        let ts = $('.tipswift');
        ts.detach();
        if (window.log_socket != undefined) {
            window.log_socket.disconnect();
        }

        // Remove hidden dialog element
        self.dialog.remove();
        window.destroyDialog(self);

        if (o && o.customClose && typeof (o.customClose) === 'function') {
            o.customClose();
        }
    });

    $(this.dialog).on('dialogdrag', (event, ui) => {
    // Disable scrolling by dragging
        $(window).scrollTop(self.startScrollPosition);
        // Set min and max position for modal window
        ui.position.top = Math.max(ui.position.top, 0);
        ui.position.top = Math.min(ui.position.top, $(window).height() - $(event.target).parent().height());
        closeFloatElementsInside();
    });

    $(this.dialog).on('dialogresizestop', function () {
        let panelHeight = $(this).parent().height();
        let panelWidth = $(this).parent().width();
        let titleBarHeight = $(this).prev('.ui-dialog-titlebar').outerHeight();
        let buttonBarHeight = $(this).parent().find('.ui-dialog-buttonpane').outerHeight();
        let contentHeightOffset = $(this).outerHeight() - $(this).height();
        let contentWidthOffset = $(this).outerWidth() - $(this).width();

        $(this).height(panelHeight - titleBarHeight - buttonBarHeight - contentHeightOffset);
        $(this).width(panelWidth - contentWidthOffset);
    });

    this.open = function (callbacks) {
        let xhr;
        // Set position fixed for modal window
        $(this.dialog).parent().css('position', 'fixed');

        // Save current scroll position
        this.startScrollPosition = $(window).scrollTop();
        this.enableScrollingAfterClose = true;
        // If already open modal windows
        if ($('.ui-dialog.ui-widget').length > 1) {
            this.enableScrollingAfterClose = false;
        }

        this.dialog.attr('unselectable', 'on').dialog('open');
        this.content.html('<br><div id="dialog_loader"></div>');
        $('#dialog_loader').LJS({ left: 100 });
        $(this.dialog).parent().attr('id', window.urlToId(url, 'dialog'));
        $(this.dialog).parent().data('url_model', url);

        // Replace close button to fluent icon
        let closeButton = $(this.dialog).closest('.spl-dialog').find('.ui-dialog-titlebar-close');
        closeButton.empty();
        closeButton.append('<i class="fa fa-close icon-ic_fluent_dismiss_24_regular"></i>');

        let abortXhr = function () {
            if (xhr && typeof xhr.abort === 'function') {
                xhr.abort();
            }
        };

        let self = this;
        xhr = $.ajax({
            url,
            dataType: 'json',
            data,
            type: (typeof (data) === 'undefined' ? 'GET' : 'POST'),
            beforeSend() {
                self.dialog.on('dialogclose', abortXhr);
            },
            complete() {
                self.dialog.off('dialogclose', abortXhr);
            },
            success(data) {
                $('#dialog_loader').LJS('Stop');

                if (!isset(data, 'dialog')) {
                    if (isset(data, 'message')) {
                        if (Array.isArray(data.message)) {
                            show_error(data.message[0], 5);
                        } else {
                            show_error(data.message, 5);
                        }
                    } else {
                        show_error('Can\'t open dialog - invalid dialog data!', 5);
                    }

                    self.close();
                    return;
                }

                $.each(data.dialog, (name, value) => {
                    if (name == 'height') {
                        value += 45; // Fix offsets
                        // Modal height must be equal or lower than window height
                        if (value > $(window).height()) {
                            value = $(window).height();
                        }
                    }
                    // set dialog wrapper id option
                    if (name === 'id') {
                        $(self.dialog).parent().attr('id', value);
                    }
                    self.option(name, value);
                });
                let customContentButtons = [];
                if (data.button) {
                    let btn = [];
                    $.each(data.button, (n, v) => {
                        if (typeof v === 'object') {
                            if (!empty(v.customContent)) {
                                customContentButtons.push({
                                    id: v.id,
                                    content: v.customContent,
                                });
                            }

                            btn.push({
                                text: n,
                                id: v.id,
                                click() {
                                    eval(v.click);
                                },
                            });
                        } else {
                            btn.push({
                                text: n,
                                click() {
                                    eval(v);
                                },
                            });
                        }
                    });
                    self.option('buttons', btn);
                }

                self.content.html(data.content);

                makeSelect2();

                makeMultileSelect();

                makeDatepicker();

                makeDecimalInput();

                processPasswords();

                processValidationgInputs();

                if (data.script != undefined) {
                    eval(data.script);
                }
                if (callbacks != undefined) {
                    if (callbacks.success != undefined && typeof callbacks.success == 'function') {
                        callbacks.success();
                    }
                }

                $.each(customContentButtons, (index, value) => {
                    $(`#${value.id}`).replaceWith(value.content);
                });
            },
            error(xhr) {
                let response = xhr.responseText;
                self.close();
                if (xhr.statusText === 'abort') {
                    return;
                }
                if (response.indexOf('top.location.href') !== -1) {
                    // If for example response is: top.location.href = '" . $url . "';"...
                    document.location.reload();
                } else {
                    // If for example response is: <b>Fatal error</b>...
                    show_error('Error', 5);
                }
            },
        });
        return this;
    };

    this.close = function () {
        if (!this.dialog.dialog('instance')) {
            return;
        }
        this.dialog.dialog('close');
    };

    splynx_event_bus.reinitEvent('before_switch_page', () => {
        this.close();
    });
};

window.downloadAllFiles = function () {
    window.location.href = window.location.origin + $('#ticket_download_all').val();
};

window.confirm_dialog = function (ask, title, height) {
    if (typeof title === 'undefined') {
        title = t('common', 'Confirm action');
    }

    return new Promise((resolve, reject) => {
        let buttons = {};
        buttons[t('common', 'Yes')] = function () {
            $(this).dialog('close');
            resolve();
        };
        buttons[t('common', 'No')] = function () {
            $(this).dialog('close');
            reject();
        };

        show_dialog(title, ask, {
            dialog: {
                modal: true,
                buttons,
                height: height != undefined ? height : '250',
                width: '350',
            },
        });
    });
};

/**
 * Close opened dialog
 */
window.closeLastDialog = function () {
    window._closeLastDialogInArray(window.splynxDialogs);
};

window.closeLastAddonDialog = function () {
    window._closeLastDialogInArray(window.splynxAddonDialogs);
};

window._closeLastDialogInArray = function (dialogs) {
    if (empty(dialogs)) {
        return;
    }

    let lastDialogIndex = dialogs.length - 1;
    let lastDialog = dialogs[lastDialogIndex];
    lastDialog.close();
    dialogs.splice(lastDialogIndex, 1);
    $(document).trigger('dialog-closed', lastDialog);
};

window.closeAllDialogs = function () {
    window._closeDialogs(window.splynxDialogs);
};

window.closeAllVueDialogs = function () {
    const isBaseLayout = window.xApp.$children[0].$refs && window.xApp.$children[0].$refs?.modalWrapper;
    if (isBaseLayout) {
        window.xApp.$children[0].$refs?.modalWrapper?.modals?.forEach((modal) => {
            window.xApp.$modals.dismiss(modal.name);
        });
    } else {
        window.xApp.$children[0].$children[0].$refs?.modalWrapper?.modals?.forEach((modal) => {
            window.xApp.$modals.dismiss(modal.name);
        });
    }
};

window.closeAllAddonDialogs = function () {
    window._closeDialogs(window.splynxAddonDialogs);
};

window._closeDialogs = function (dialogs) {
    if (empty(dialogs)) {
        return;
    }

    let dialogsCount = dialogs.length;
    for (let i = 0; i < dialogsCount; i++) {
        let dialog = dialogs.pop();
        if (typeof dialog.close === 'function') {
            dialog.close();
            $(document).trigger('dialog-closed', dialog);
        }
    }
};

window.getLastDialog = function () {
    return window._getLastDialogFromArray(window.splynxDialogs);
};

window.getLastAddonDialog = function () {
    return window._getLastDialogFromArray(window.splynxAddonDialogs);
};

window._getLastDialogFromArray = function (dialogs) {
    if (empty(dialogs)) {
        return null;
    }

    return dialogs[dialogs.length - 1];
};

window.registerDialog = function (dialog) {
    window.splynxDialogs.push(dialog);
};

window.registerAddonDialog = function (dialog) {
    window.splynxAddonDialogs.push(dialog);
};

window.destroyDialog = function (dialog) {
    window._destroyDialogFromArray(window.splynxDialogs, dialog);
    window._destroyDialogFromArray(window.splynxAddonDialogs, dialog);
};

window._destroyDialogFromArray = function (dialogs, dialogToDestroy) {
    if (!empty(dialogs)) {
        for (let i = 0; i < dialogs.length; i++) {
            if (dialogToDestroy === dialogs[i]) {
                dialogs.splice(i, 1);
                $(document).trigger('dialog-closed', dialogToDestroy);
                return;
            }
        }
    }
};

/**
 * Close daterangepickers in scope
 * @param string scope Element selector
 */
window.closeDatepicker = function (scope) {
    let element = scope !== undefined ? $(scope) : $('.content.active');

    element.find(':data(daterangepicker)').each((i, e) => {
        if (typeof $(e).data('daterangepicker').hide === 'function') {
            $(e).data('daterangepicker').hide();
        }
    });
};

/**
 * Close Select2 selects
 * @param string scope Element selector
 */
window.closeSelects = function (scope) {
    let element = scope !== undefined ? $(scope) : $('.content.active');
    element.find('select.select2, select.async-select').each((i, e) => {
        if ($(e).data('select2')) {
            // Select2 has been initialized, SPL-3574
            $(e).select2('close');
        }
    });
};

/**
 * Close multiselects
 * @param string scope Element selector
 */
window.closeMultipleSelects = function (scope) {
    let element = scope !== undefined ? $(scope) : $('.content.active');
    element.find('select[multiple="multiple"]').each((i, e) => {
        $(e).multipleSelect('close');
    });
};

/**
 * Removes daterangepicker instances attached inside element
 * @param element
 */
window.removeDatepicker = function (element) {
    $(element).find(':data(daterangepicker)').each((i, e) => {
        if (typeof $(e).data('daterangepicker').remove === 'function') {
            $(e).data('daterangepicker').remove();
        }
    });
};

/**
 * Remove WebUiPopovers from html
 * @usage on switch page
 */
window.removeWebuiPopover = function () {
    $('body').find('.webui-popover.in, .webui-popover.out').each(function () {
        $(this).remove();
    });
};

window.urlToId = function (url, prefix, delimiter) {
    if (url == undefined) return null;
    if (prefix == undefined) prefix = '';
    if (delimiter == undefined) delimiter = '-';
    let parser = document.createElement('a');
    parser.href = url;
    // strip slashes from path replace / and -- with delimiter symbol
    let generatedId = parser.pathname.replace(/^\/+|\/+$/g, '').replace(/\/+|-+/g, delimiter);
    return prefix ? prefix + delimiter + generatedId : generatedId;
};

window.processDT = function () {
    $('#content .active .dataTables_wrapper').each(function () {
        let x = $(this).attr('id');
        if ($(this).is(':visible')) {
            if (x == undefined) return;
            let z = x.replace('_wrapper', '');
            let tableElem = $(`#${z}`);
            let t = tableElem.DataTable();
            $(`#${z}`).css('width', '100%');
            t.columns.adjust();
            t.responsive.recalc();
            // SPL-4376 Fix double request
            let fixDoubleRequestArr = [
                'admin_support_tickets_opened_list',
                'admin_support_tickets_closed_list',
                'admin_support_tickets_trash_list',
                'admin_customers_online_list_table',
                'customers_list_table',
                'admin_finance_invoice_list',
            ];

            let draw = true;

            if (fixDoubleRequestArr.indexOf(z) !== -1) {
                draw = false;
            }

            if (tableElem.attr('data-server-side') && draw === true) {
                draw = tableElem.attr('data-server-side') === 'true';
            }

            if (draw) {
                t.draw();
            }
        }
    });
};

window.loadUI = function () {
    $('.loader').show();
    $('.loader').stop();
    $('.loader').css({
        left: ($(document).width() - 18) / 2,
        opacity: '0.2',
        backgroundColor: '#FFFFFF',
    }).fadeTo(300, 0.4);
};

window.unloadUI = function () {
    window.is_animate = false;
    $('.loader').fadeTo(300, 0, () => {
        $('.loader').hide();
        $('.loader').stop(true, false);
    });
};

$(() => {
    $('.loader').fadeTo(0, 0);
    window.registerLogoutHandler();
});

window.show_dialog = function (title, content, o, afterOpenCallback, afterCloseCallback) {
    new window.small_dialog(title, content, o, afterOpenCallback, afterCloseCallback).open();
};

window.open_dialog = function (path, id, id2) {
    if (id == undefined) id = '';
    let dialog = null;
    if (id2 == null) {
        dialog = new splynx_dialog(`/admin/${path}/${id}`, {
            dialog: {
                modal: true,
            },
        }).open();
    } else {
        dialog = new splynx_dialog(`/admin/${path}/${id}/${id2}`, {
            dialog: {
                modal: true,
            },
        }).open();
    }

    registerDialog(dialog);
};

(function ($) {
    $.fn.deSubmit = function () {
        return this.each(function () {
            let form = $(this);
            form.off('submit');
            form.submit(() => false);
            $('#tiptip_holder').fadeOut(0);
        });
    };
}(jQuery));

window.open_dialog_new = function (path, data) {
    let dialog = new splynx_dialog(`/admin/${path}`, {
        dialog: {
            modal: true,
        },
    }, data).open();
    window.registerDialog(dialog);
};

window.open_addon_dialog = function (path, data) {
    let dialog = new splynx_dialog(`/admin/${path}`, {
        dialog: {
            modal: true,
        },
    }, data).open();
    window.registerAddonDialog(dialog);
};

window.open_password_window = function (path, data) {
    window.password_window_id = new splynx_dialog(`/admin/${path}`, {
        dialog: {
            modal: true,
        },
    }, data).open();
    window.registerDialog(window.password_window_id);
};

window.open_image_window = function (path, data) {
    window.image_window_id = new splynx_dialog(`/admin/${path}`, {
        dialog: {
            modal: true,
        },
    }, data).open();
    window.registerDialog(window.image_window_id);
};

window.open_portal_dialog = function (path, data) {
    let dialog = new splynx_dialog(`/portal/${path}`, {
        dialog: {
            modal: true,
        },
    }, data).open();
    window.registerDialog(dialog);
};

window.open_dialog_for_save_template = function (path, data) {
    window.dialog_for_save_template_id = new splynx_dialog(`/admin/${path}`, {
        dialog: {
            modal: true,
        },
    }, data).open();
    window.registerDialog(window.dialog_for_save_template_id);
};

window.processPasswords = function () {
    // Select all unprocessed elements
    $('.password_input:not([processed])').each(function () {
        $(this).attr('name', $(this).next('.password_input[type=hidden]').attr('name'));
        $(this).nextAll('.password_input[type=hidden]').remove();
        $(this).off();

        // Create hidden clone for password input
        let hidden_input = $(this).clone();
        $(hidden_input).attr('type', 'hidden');
        $(hidden_input).removeClass('password_input');
        $(hidden_input).off();

        $(this).removeAttr('name');
        $(this).removeAttr('id');
        $(this).after(hidden_input);

        initializeHandlers(this);

        // Mark this element as processed
        $(this).attr('processed', '1');
    });

    function initializeHandlers(element) {
        const visibleElement = $(element);
        const hiddenElement = visibleElement.nextAll('input[type="hidden"]');

        const input = new window.MaskedInput(visibleElement, true, (dry) => {
            // On value change
            $(hiddenElement).val(dry).change();
        }, (newState) => {
            // On change visible state
            const button = $(element).prev('.toggle-password');
            const icon = button.find('i');
            if (!newState) {
                button.attr('title', t('common', 'Hide password'));
                icon.addClass('icon-ic_fluent_eye_off_24_regular');
                icon.removeClass('icon-ic_fluent_eye_24_regular');
            } else {
                button.attr('title', t('common', 'Show password'));
                icon.removeClass('icon-ic_fluent_eye_off_24_regular');
                icon.addClass('icon-ic_fluent_eye_24_regular');
            }
        });
        $(element).prev('.toggle-password').click(function () {
            $(this).toggleClass('visible');
            input.toggleMaskState();
        });
    }
};

window.setSelectionRange = (input, selectionStart, selectionEnd) => {
    if (input.setSelectionRange) {
        input.focus();
        input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
        let range = input.createTextRange();
        range.collapse(true);
        range.moveEnd('character', selectionEnd);
        range.moveStart('character', selectionStart);
        range.select();
    }
};

window.setCaretToPosition = (input, pos) => {
    window.setSelectionRange(input, pos, pos);
};

jQuery.fn.center = function () {
    this.css('position', 'absolute');
    this.css('top', `${Math.max(0, (($(window).height() - $(this).outerHeight()) / 2)
        + $(window).scrollTop())}px`);
    this.css('left', `${Math.max(0, (($(window).width() - $(this).outerWidth()) / 2)
        + $(window).scrollLeft())}px`);
    return this;
};

window.toggleMobileMenu = function () {
    $('.menu-bar').closest('.splynx-wrapper').toggleClass('mobile-menu-open');
};

window.supports_html5_storage = function () {
    try {
        return 'localStorage' in window && window.localStorage !== null;
    } catch (e) {
        return false;
    }
};

window.address_reload = function (checkUnsavedData = false, hardReload = false) {
    // If the model window is open, then do not reload the page, but reload only the modal window
    // @see https://jira.splynx.com/browse/SPL-6364
    if ($('.ui-dialog').is(':visible')) {
        let url_model = $('.ui-dialog').data('url_model');
        closeLastDialog();
        open_dialog_new(url_model.replace('/admin/', ''));
        return;
    }

    let reload = function () {
    // If vuejs is not connected, for example on the portal, there will be an error
        if (typeof xApp !== 'undefined') {
            if (hardReload) {
                xApp.$router.go(xApp.$router.currentRoute);
                return;
            }
            xApp.refreshRoute();
            return;
        }
        $.ajax({
            cache: false,
            complete: window.processHTML,
            url: $.address.value(),
        });
    };

    if (!checkUnsavedData) {
        reload();
    } else {
        checkUnsavedDataBeforeLeavePage(() => {
            reload();
        });
    }
};

// date / time
window.isDateEq = function (date1, date2) {
    if (date1.getFullYear() != date2.getFullYear()) return false;
    if (date1.getMonth() != date2.getMonth()) return false;
    if (date1.getDate() != date2.getDate()) return false;
    return true;
};

window.addDays = function (date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + parseInt(days));
    return result;
};

window.resetForm = function ($form) {
    $form.find('input:text, input:password, input:file, textarea').val('');
    $form.find('input:radio, input:checkbox').removeAttr('checked').removeAttr('selected');
    $form.find('textarea').froalaEditor('html.set', '');
    $form.find('select').each((i, el) => {
        let options = $(el).find('option');
        if (Array.isArray(options) && options[0] !== undefined) {
            let defaultValue = $(el).find('option')[0].value;
            $(el).val(defaultValue).trigger('change');
        }
    });
};

window.formatTicketStatus = function (item) {
    return $(`<span class="badge bg-${item.id}">${item.text}</span>`);
};

/**
 * Generate password for additional field
 * @param context
 * @param placement
 */
window.generatePasswordForAf = function (context, placement) {
    let input = $(context).closest('div').find('.password_input');
    let inputHidden = $(input).next('input[type="hidden"]');
    if ((input.length === 0 || inputHidden.length === 0)
        || (inputHidden.val() !== '' && window.confirm(t('config', 'Are you sure you want to generate a random password?')) === false)) {
        return;
    }
    let matches = $(inputHidden).attr('name').match(/^(.*?)\[(.*?)\]\[(.*?)\]+$/i);
    $.ajax({
        type: 'GET',
        url: `/${placement}/tools--generate-password-for-af?module=${matches[1]}&field=${matches[3]}`,
        dataType: 'json',
        success(response) {
            // Replace old password in visible and hidden inputs
            const maskedInput = window.maskedInputs[input.attr('data-input-id')];
            maskedInput.setMaskState(false);
            maskedInput.setValue(response.string);
        },
    });
};

/**
 * Generate password
 * @param context Password input element
 * @param string placement Available values (admin | portal)
 */
window.generatePassword = function (context, placement) {
    placement = placement || 'admin';
    let input = $(context).closest('div').find('.password_input');
    $.ajax({
        type: 'GET',
        url: `/${placement}/tools--generate-password`,
        dataType: 'json',
        success(response) {
            // Replace old password in visible and hidden inputs
            const maskedInput = window.maskedInputs[input.attr('data-input-id')];
            maskedInput.setMaskState(false);
            maskedInput.setValue(response.string);
        },
    });
};

/**
 * Show password
 * @param context Password input element
 */
window.showPassword = function (context) {
    let input = $(context).closest('div').find('.password_input');
    input.focus();
    this.changePasswordIcon($(context).closest('div'));
};

window.changePasswordIcon = function (context) {
    if (context.find('.icon-ic_fluent_eye_24_regular').length
        || context.find('.icon-ic_fluent_eye_off_24_regular').length) {
        context.find('.icon-ic_fluent_eye_24_regular')
            .removeClass('icon-ic_fluent_eye_24_regular')
            .addClass('icon-ic_fluent_eye_off_24_regular');
        context.find('.password_input').on('focus', () => {
            context.find('.icon-ic_fluent_eye_24_regular')
                .removeClass('icon-ic_fluent_eye_24_regular')
                .addClass('icon-ic_fluent_eye_off_24_regular');
        });
    }
};

window.decodeURIComponentExtended = function (str) {
    let out = ''; let arr; let i = 0; let l; let
        x;
    arr = str.split(/(%(?:D0|D1)%.{2})/);
    for (l = arr.length; i < l; i++) {
        try {
            x = decodeURIComponent(arr[i]);
        } catch (e) {
            x = arr[i];
        }
        out += x;
    }
    return out;
};

window.manageVisibilityForCustomerInfoInputs = function (categoryElement) {
    let category = $(categoryElement).val();

    let showCustomerInfoInputs = function (elements) {
        $(elements).each(function () {
            $(this).closest('.row').show(200);
            $(this).prop('disabled', false);
        });
    };

    let hideCustomerInfoInputs = function (elements) {
        $(elements).each(function () {
            $(this).closest('.row').hide(200);
            $(this).prop('disabled', true);
        });
    };

    if (category == 'company') {
        hideCustomerInfoInputs($('.inputs-for-person-category'));
        showCustomerInfoInputs($('.inputs-for-company-category'));
    } else if (category == 'person') {
        hideCustomerInfoInputs($('.inputs-for-company-category'));
        showCustomerInfoInputs($('.inputs-for-person-category'));
    } else {
        hideCustomerInfoInputs($('.inputs-for-person-category, .inputs-for-company-category'));
    }
};

/**
 * This function decode string.
 * Example: "&lt;h1&gt;Hello&lt;/h1&gt;" will be converted to "<h1>Hello</h1>"
 * @param html
 */
window.decodeHtml = function (html) {
    let txt = document.createElement('textarea');
    txt.innerHTML = html;
    return txt.value;
};

window.registerLogoutHandler = function () {
    $('.fa-sign-out').parent().each(function () {
        let url = this.href;
        this.href = '#';
        $(this).on('click', (event) => {
            event.preventDefault();
            logout(url);
            return false;
        });
    });
};

window.logout = function (logoutUrl) {
    // disable refresh current page via socket
    if (typeof (window.logout_listener) != 'undefined') {
        window.remove_socket_listener(window.logout_listener);
    }
    $.ajax({
        url: logoutUrl,
        type: 'POST',
        success(response) {
            $('body').append(response);
        },
    });
};

window.blockquoteHandler = function () {
    let blockquotes = $('.message-with-blockquote').find('blockquote').first();
    blockquotes.each((i, item) => {
        if (!$(item).hasClass('is-added')) {
            let label = $(`<label class="badge bg-info show-blockquote" title="${t('support', 'Show trimmed content')}">...</label>`).insertBefore($(item));
            label.click(function () {
                $(this).next().toggle();

                if ($(this).next().is(':visible')) {
                    $(this).attr('title', t('support', 'Hide trimmed content'));
                } else {
                    $(this).attr('title', t('support', 'Show trimmed content'));
                }
            });
        }
        $(item).addClass('is-added');
        $(item).hide();
    });
};

/**
 * Generate random string
 * @param int length Length of generated string
 * @param string possibleChars Possible chars
 * @returns string
 */
window.randomString = function (length, possibleChars) {
    if (Number.isNaN(length) || length <= 0) {
        length = 16;
    }

    if (typeof possibleChars !== 'string') {
        possibleChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    }

    let text = '';

    for (let i = 0; i < length; i++) {
        text += possibleChars.charAt(Math.floor(Math.random() * possibleChars.length));
    }

    return text;
};

/**
 * Show webhook help modal window
 * @param string url
 * @returns {*} Dialog instance
 */
window.open_dialog_by_url = function (url) {
    let dialog = new splynx_dialog(url, {
        dialog: {
            modal: true,
        },
    }).open();
    registerDialog(dialog);
    return dialog;
};

/**
 * Check if var is empty. Analog PHP function empty()
 *
 * @param variable
 * @returns {boolean}
 */
window.empty = function (variable) {
    return (
        variable == undefined
        || variable === ''
        || variable === 0
        || variable === '0'
        || variable === null
        || variable === false
        || (variable instanceof Array && variable.length === 0)
        || (variable instanceof Object && Object.keys(variable).length === 0)
    );
};

/**
 * Disable all inputs and selects in html block
 * @param block
 */
window.disableInputsInBlock = function (block) {
    $(block).find('input,select').each(function () {
        $(this).prop('disabled', true).addClass('disabled');
    });
};

/**
 * Enable all inputs and selects in html block
 * @param block
 */
window.enableInputsInBlock = function (block) {
    $(block).find('input,select').each(function () {
        $(this).prop('disabled', false).removeClass('disabled');
    });
};

/**
 * Enable checkboxes
 * @param variable id || variables ids
 */
window.disableInput = function (...variables) {
    variables.forEach((item) => {
        item.prop('disabled', '1').addClass('disabled');
    });
};

/**
 * Disable input
 * @param variable id || variables ids
 */
window.enableInput = function (...variables) {
    variables.forEach((item) => {
        item.prop('disabled', false).removeClass('disabled');
    });
};

/**
 * Check if checked for input[type='checkbox'] returns boolean
 * @param variable selector
 */
window.isCheckedInput = function (variable) {
    let prop = variable.prop('checked');
    if (typeof prop !== 'undefined' && prop !== false) {
        return true;
    }
    return false;
};

/**
 * Change negative position for current state for input[type='checkbox']
 * @param variable selector, boolean - position nedeed in result
 */
window.changePosition = function (element, checked) {
    if (($(element).is(':checked') && checked == false) || (!$(element).is(':checked') && checked == true)) {
        $(element).trigger('click');
    }
};

window.drawMenuLengthForDataTable = function (id, spp, current) {
    let serverSideLength = {
        10: 10,
        25: 25,
        50: 50,
        75: 75,
        100: 100,
        '-1': 'All',
    };

    let normalLength = {
        10: 10,
        25: 25,
        50: 50,
        75: 75,
        100: 100,
    };

    let forContent = spp ? serverSideLength : normalLength;

    if (!isset(forContent, [current])) {
        current = 100;
    }

    $(`#${id}_length`).find('option').remove();

    for (let key in forContent) {
        if (Object.prototype.hasOwnProperty.call(forContent, key)) {
            $(`select[name=${id}_length]`).append($('<option/>', {
                value: key,
                text: forContent[key],
            }));
        }
    }

    $(`select[name=${id}_length]`).val(current);
};

/**
 * Open help dialog by page path
 * @param path
 * @returns {*}
 */
window.open_help_dialog = function (path) {
    let documentationConfig = JSON.parse(window.spl_config.documentationConfig);
    let { api_url, lang, version } = documentationConfig;
    let url = `${api_url}/api/pages/by-path/${path}?lang=${lang}&version=${version}`;

    $.ajax({
        url,
        method: 'GET',
        dataType: 'json',
        success(response) {
            showModal('help-documentation-modal', window.formattedResponse(response));
        },
        error(response) {
            show_error(window.t('common', 'Something went wrong!'), 4);
            console.error(`Open page , ${url} failed with response:`, response);
        },
    });
};

window.formattedResponse = function (response) {
    if (typeof response !== 'object') {
        return response;
    }
    return Object.keys(response).reduce((acc, key) => {
        let newKey = window.toCamelCase(key);
        acc[newKey] = response[key];
        return acc;
    }, {});
};

window.toCamelCase = function (str) {
    if (typeof str !== 'string') {
        return '';
    }
    return str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

window._exportTextarea = $('<textarea/>')[0];

window.buttonCommon = {
    exportOptions: {
        orthogonal: 'export',
        format: {
            body(data) {
                if (typeof data !== 'string') {
                    return data;
                }

                // Hack for sortable some values
                data = data.replace(/<span.*display:.*none.*>.*<\/span>/gm, '');

                // Remove additional letter from avatar
                data = data.replace(/<div class='avatar-holder.*>.*<div class='avatar-letter.*>.*<\/div><\/div>/gm, '');

                // Always remove comments
                data = data.replace(/<[^>]*>/g, '');
                data = data.replace(/^\s+|\s+$/g, '');
                data = data.replace(/\n/g, ' ');

                window._exportTextarea.innerHTML = data;
                return window._exportTextarea.value;
            },
        },
    },
};

window.default_buttons = [
    {
        extend: 'collection',
        text: '<i class="icon-ic_fluent_arrow_export_ltr_24_regular"></i>',
        titleAttr: 'Export to',
        background: false,
        container: '#colvis',
        className: 'btn-outline-secondary btn-icon',
        dropup: true,
        buttons: [
            $.extend(true, {}, window.buttonCommon, {
                extend: 'print',
            }),
            $.extend(true, {}, window.buttonCommon, {
                extend: 'copyHtml5',
            }),
            $.extend(true, {}, window.buttonCommon, {
                extend: 'excelHtml5',
                filename: '*',
            }),
            $.extend(true, {}, window.buttonCommon, {
                extend: 'csvHtml5',
                fieldSeparator: '\t',
                extension: '.csv',
                filename: '*',
            }),
            $.extend(true, {}, window.buttonCommon, {
                extend: 'pdfHtml5',
                filename: '*',
                available() {
                    return typeof window.FileReader !== 'undefined';
                },
                action(e, dt, node, config) {
                    if (typeof window.pdfMake === 'undefined') {
                        show_success(window.t('common', 'Please wait. Download will start automatically'), 4);
                        $.getScript('/js/development/pdfmake/pdfmake.min.js', () => {
                            $.getScript('/js/development/pdfmake/vfs_fonts.js', () => {
                                $.fn.DataTable.ext.buttons.pdfHtml5.action.call(this, e, dt, node, config);
                            });
                        });
                    } else {
                        $.fn.DataTable.ext.buttons.pdfHtml5.action.call(this, e, dt, node, config);
                    }
                },
            }),
        ],
    },
];

window.getDataTableButtons = function (modelName) {
    let buttons = window.getDefaultButtons();
    let showHideButtons = window.getShowHideButton(modelName);
    if (showHideButtons) {
        buttons.unshift(showHideButtons);
    }

    return buttons;
};

window.getDefaultButtons = function () {
    return default_buttons.slice();
};

window.getShowHideButton = function (modelName) {
    if (modelName) {
        return [
            {
                container: '#colv',
                className: 'btn-outline-secondary btn-icon',
                text: '<i class="icon-ic_fluent_more_horizontal_24_regular"></i>',
                action() {
                    open_dialog_new(`profile--show-hide-columns?name=${modelName}`);
                },
                titleAttr: 'Show/hide columns',
            },
        ];
    }
    return [];
};

/**
 * Enlarge image when click on that
 * @param img
 */
window.imageEnlarging = function (img) {
    let modalHtml = ''
        + '<div class="modal fade modal-profile img-enlarge-modal" tabindex="-1" role="dialog" aria-labelledby="modalProfile" aria-hidden="true" style="visibility: hidden;">'
        + '    <div class="modal-dialog modal-lg" style="margin: 0 auto;width: fit-content;width: -moz-max-content;width: -webkit-max-content;min-width: 200px;min-height:150px;max-width: 100%;">'
        + '        <div class="modal-content">'
        + '            <div class="modal-header empty-header">'
        + '                <button class="btn-close" type="button" data-bs-dismiss="modal"><span aria-hidden="true"></span></button>'
        + '            </div>'
        + '            <div class="modal-body" style="text-align: center;">'
        + '            </div>'
        + '        </div>'
        + '    </div>'
        + '</div>';

    $(img).click(function (e) {
        e.preventDefault();

        // Remove prevent modal
        $('.img-enlarge-modal').remove();
        let img = $(this).clone();

        // Fix styles
        img.attr('width', '');
        img.attr('height', '');
        img.css('width', '');
        img.css('max-width', '100%');
        img.css('height', '');
        img.css('display', 'inline-block');
        img.removeClass();
        img.addClass('img-responsive center-block');

        $('body').append(modalHtml);
        $('.img-enlarge-modal .modal-body').append(img);

        if ($(img).data('download')) {
            let footerButton = `<div class="modal-footer">
                <a
                    href="${$(img).data('download')}"
                    class="btn btn-primary"
                    download
                >
                    Download
             </a></div>`;
            $('.img-enlarge-modal .modal-content').append(footerButton);
        }

        setTimeout(() => {
            let modalHeight = $('.img-enlarge-modal').find('.modal-dialog').outerHeight(true);
            let marginModal = $('.img-enlarge-modal').outerHeight(true) - $('.img-enlarge-modal').outerHeight();
            let calcHeight = modalHeight - img.outerHeight() + marginModal;
            img.css('max-height', `calc(100vh - ${calcHeight}px)`);
            $('.img-enlarge-modal').css('visibility', 'visible');
        }, 200);

        $('.img-enlarge-modal').modal('show');

        // Hide modal when click on image
        $('.img-enlarge-modal .modal-body img').on('click', () => {
            $('.img-enlarge-modal').modal('hide');
        });

        // Remove modal html after close
        $('.img-enlarge-modal').on('hidden.bs.modal', function () {
            $(this).remove();
        });
    });
};

window.setModalForTicketImages = function () {
    $('.ticket-messages').find('.message-with-blockquote img').each((_, img) => {
        $(img).css('cursor', 'pointer');
        imageEnlarging(img);
    });
};

window.collapsePanel = function (panel, animate, callback, saveState) {
    if (typeof animate === 'undefined') {
        animate = true;
    }

    if (typeof saveState === 'undefined') {
        saveState = true;
    }

    $(panel).find('.card-header, .card-block-header').removeClass('dropup');

    if (animate) {
        $(panel).find('.card-body, .card-block-body').hide('fast', callback);
    } else {
        $(panel).find('.card-body, .card-block-body').hide();
        if (typeof callback === 'function') {
            callback();
        }
    }

    if (saveState) {
        window.savePanelState(panel, SPL_PANEL_STATE_HIDDEN);
    }
};

window.expandPanel = function (panel, animate, callback) {
    if (typeof animate === 'undefined') {
        animate = true;
    }

    $(panel).find('.card-header, .card-block-header').addClass('dropup');

    if (animate) {
        $(panel).find('.card-body, .card-block-body').show('fast', callback);
    } else {
        $(panel).find('.card-body, .card-block-body').show();
        if (typeof callback === 'function') {
            callback();
        }
    }

    window.savePanelState(panel, SPL_PANEL_STATE_VISIBLE);
    $(document).trigger('panel-expanded');
};

window.checkFroalaEmptyContent = (content) => !content.replace(/<(?:"[^"]br"['"]div|'[^']br'['"]div|[^'">])+>|&nbsp;|\s+/g, '');

window.fixFroalaHeight = function () {
    $('.froala').froalaEditor('size.syncIframe');
    $('.froala').froalaEditor('size.refresh');
};

const SPL_PANEL_STATE_KEY_PREFIX = 'spl_panel_states_';
const SPL_PANEL_STATE_HIDDEN = 'hidden';
const SPL_PANEL_STATE_VISIBLE = 'visible';

window.savePanelState = function (panel, state) {
    let panelId = $(panel).attr('id');
    if (typeof panelId === 'undefined' || panelId.length <= 0) {
        return;
    }

    if (!supports_html5_storage()) {
        return;
    }

    let itemName = SPL_PANEL_STATE_KEY_PREFIX + panelId;
    localStorage.setItem(itemName, state);
};

window.collapsePanelClickHandler = function (event) {
    if (event.target !== this && !window.isCollapseItem(event)) {
        return;
    }
    let panel = $(this).closest('.spl-collapsed-panel');
    let panelBody = panel.find('.card-body, .card-block-body');

    if (panel.data('toggle-in-progress')) {
        return;
    }

    if (panelBody.is(':visible')) {
        panel.data('toggle-in-progress', true);
        $(panel).trigger('collapse');

        window.collapsePanel(panel, true, () => {
            panel.data('toggle-in-progress', false);
            $(panel).trigger('collapsed');
        });
    } else {
        panel.data('toggle-in-progress', true);
        $(panel).trigger('expand');

        window.expandPanel(panel, true, () => {
            panel.data('toggle-in-progress', false);
            $(panel).trigger('expanded');
        });
    }
};

window.isCollapseItem = function (event) {
    let isEqual = false;
    $(event.currentTarget).find('i.icon, span.close-card-btn, strong').each((i, element) => {
        if (event.target == element) {
            isEqual = true;
        }
    });
    return isEqual;
};

window.processCollapsedPanels = function (container) {
    if (typeof container === 'undefined') {
        container = $('body');
    }

    $(container).find('.spl-collapsed-panel > .card-header, .spl-collapsed-panel > .card-block-header').unbind('click', window.collapsePanelClickHandler);
    $(container).find('.spl-collapsed-panel > .card-header, .spl-collapsed-panel > .card-block-header').on('click', window.collapsePanelClickHandler);

    $(container).find('.spl-collapsed-panel').each((index, panel) => {
        window._processCollapsedPanelState(panel);
    });
};

window.processCollapsedPanel = function (panel) {
    $(panel).find('.card-header, .card-block-header').unbind('click', window.collapsePanelClickHandler);
    $(panel).find('.card-header, .card-block-header').on('click', window.collapsePanelClickHandler);
    window._processCollapsedPanelState(panel);
};

window._processCollapsedPanelState = function (panel) {
    let panelId = $(panel).attr('id');
    let panelCollapsed = $(panel).data('collapsed');
    if (typeof panelId === 'undefined' || panelId.length <= 0) {
        console.error('Collapsed panel', panel, 'must have `id` attribute! Id attribute used as key for saving panel state!');
        return;
    }

    if (!supports_html5_storage()) {
        return;
    }

    let panelState = localStorage.getItem(SPL_PANEL_STATE_KEY_PREFIX + panelId);
    if (panelState === SPL_PANEL_STATE_HIDDEN || panelCollapsed == true) {
        window.collapsePanel(panel, false);
    } else {
        window.expandPanel(panel, false);
    }
};

window.is_gMap_loading = false;
window.is_OpenstreetMap_loading = false;
window.is_bingMap_loading = false;

/**
 * Load googleMap
 * @param key Google maps key
 * @param callback This callback will be initMap
 */
window.loadGoogleMapJs = function (key, callback) {
    if (window.is_gMap_loading) {
        callback();
        return;
    }

    window.is_gMap_loading = true;
    $.getScript(`https://maps.googleapis.com/maps/api/js?key=${key}&libraries=drawing`, () => {
        $.getScript('/js/development/oms.min.js?spiderfier_callback', callback);
    });
};

/**
 * Load openstreetMap
 * @param callback This callback will be initMap
 */
window.loadOpenstreetMapJs = function (callback) {
    if (window.is_OpenstreetMap_loading) {
        callback();
        return;
    }

    window.is_OpenstreetMap_loading = true;
    $.getScript('/js/development/openLayersNew/ol.js', callback);
};

window.bingMapCbFn = null;

/**
 * Load bingMap
 * @param key Bimg maps key
 * @param callback This callback will be initMap
 */
window.loadBingMapJs = function (key, callback) {
    window.bingMapCbFn = callback;
    if (window.is_bingMap_loading) {
        callback();
        return;
    }
    window.is_bingMap_loading = true;
    let script = document.createElement('script');
    script.src = `https://www.bing.com/api/maps/mapcontrol?callback=bingMapsCb&key=${key}`;
    document.body.appendChild(script);
};

window.bingMapsCb = function () {
    window.bingMapCbFn();
};

window.processValidationgInputs = function () {
    // Fix error: An invalid form control with name='inputName' is not focusable.
    window.removeRequiredFromHiddenInputs();
    window.addRequiredToVisibleInputs();

    $('input,select,textarea').off('invalid', window.processInvalidInputs);
    $('input,select,textarea').on('invalid', window.processInvalidInputs);
};

window.processInvalidInputs = function (e) {
    let parentBlock = $(e.target).closest('.form-group');
    if (parentBlock.length <= 0) {
        parentBlock = $(e.target).parent();
    }

    parentBlock.addClass('has-error');
    window.splynx_event_bus.emit('input-has-error', parentBlock);

    $(e.target).off('change', window.processChangeInvalidInput);
    $(e.target).on('change', window.processChangeInvalidInput);
};

window.processChangeInvalidInput = function () {
    $(this).closest('.has-error').removeClass('has-error');
};

window.removeRequiredFromHiddenInputs = function (target) {
    let elements;
    if (typeof target !== 'undefined') {
        elements = $(target).find('input[required]:hidden,select[required]:hidden,textarea[required]:hidden');
    } else {
        elements = $('input[required]:hidden,select[required]:hidden,textarea[required]:hidden');
    }

    elements.attr('hidden-required', '1').removeAttr('required');
};

window.addRequiredToVisibleInputs = function (target) {
    let elements;
    if (typeof target !== 'undefined') {
        elements = $(target).find('input[hidden-required]:visible,select[hidden-required]:visible,textarea[hidden-required]:visible');
    } else {
        elements = $('input[hidden-required]:visible,select[hidden-required]:visible,textarea[hidden-required]:visible');
    }

    elements.attr('required', '1').removeAttr('hidden-required');
};

/**
 * For required selects fix first value.
 * @example
 * Select options:
 * 0 => 'Select object',
 * 1 => 'Object 1'
 * ...
 * Initial value for select is 0, but it is not empty so we change 0 to '' for fix required logic.
 */
window.fixSelectEmptyValue = function (target) {
    let elements;
    if (typeof target !== 'undefined') {
        elements = $(target).find('select[required]:not([data-skip-fix-select])');
    } else {
        elements = $('select[required]:not([data-skip-fix-select])');
    }

    elements.each((index, item) => {
        if ($(item).val() == '0') {
            $(item).val('');
        }
    });
};

/**
 * Mark blank required inputs before send form
 */
window.initReportValidityBeforeSubmitForm = function () {
    let submitFormDecorator = function (f) {
        return function () {
            window.removeRequiredFromHiddenInputs(this);
            window.addRequiredToVisibleInputs(this);
            window.fixSelectEmptyValue(this);

            if (typeof $(this).get(0) !== 'undefined' && $(this).get(0).reportValidity()) {
                return f.apply(this, arguments);
            }

            return false;
        };
    };

    $.fn.submit = submitFormDecorator($.fn.submit);
};

window.addBeforeSubmitValidation = function () {
    $('.content.active').find('form').on('submit', function () {
        window.removeRequiredFromHiddenInputs(this);
        window.addRequiredToVisibleInputs(this);
        window.fixSelectEmptyValue(this);
    });
};

/**
 * Load Vue components
 * @param context string admin or portal
 * @param componentNames array of component names
 * @param environment Current environment (`production` or `development`)
 * @param cb This callback will be called when components loaded
 */
window.loadVueComponentsByContext = function (context, componentNames, environment, cb) {
    let componentsParam = '';
    $.each(componentNames, (idx, componentName) => {
        if ($(`#${componentName}`).length === 0) {
            componentsParam += `${componentName},`;
        }
    });
    // delete last comma
    componentsParam = componentsParam.substring(0, componentsParam.length - 1);
    if (componentsParam == '') {
        if (typeof cb === 'function') {
            cb();
        }
        return;
    }
    let rootComponentElement = $('#custom_components');
    $.ajax({
        url: `/${context}/vue-components?componentNames=${componentsParam}`,
        success(response) {
            if (response.result === true) {
                rootComponentElement.append($('<div>').html(response.data));
                if (typeof cb === 'function') {
                    cb();
                }
            } else if (response.result === false && response.message) {
                console.error('Failed load components', response.message);
            }
        },
    });
};

window.imageTicketEnlarging = function (img) {
    let modalHtml = `
        <div class="modal fade modal-profile img-enlarge-modal" tabindex="-1" role="dialog" aria-labelledby="modalProfile" aria-hidden="true" style="visibility: hidden; margin: 20px;">
            <div class="modal-dialog modal-lg" style="margin: 0 auto;width: fit-content;width: -moz-max-content;width: -webkit-max-content;min-width: 200px;min-height:150px;max-width: 100%;">
               <div class="modal-content">
                    <div class="modal-header empty-header">
                        <button class="btn-close" type="button" data-dismiss="modal">×</button>
                    </div>
                    <div class="modal-body" style="text-align: center;">
                    </div>
                    <div class="modal-footer">
                       <a href="${$(img).data('download')}" class="btn btn-primary" download>Download</a>
                    </div>
               </div>
            </div>
        </div>`;

    // Remove prevent modal
    $('.img-enlarge-modal').remove();
    $('body').append(modalHtml);

    let insertImg = $(img).clone();

    // Fix styles
    insertImg.attr('width', '');
    insertImg.attr('height', '');
    insertImg.css('width', '');
    insertImg.css('max-width', '100%');
    insertImg.css('height', '');
    insertImg.css('display', 'inline-block');
    insertImg.removeClass();
    insertImg.addClass('img-responsive center-block');
    $('.img-enlarge-modal .modal-body').append(insertImg);

    setTimeout(() => {
        let modalHeight = $('.img-enlarge-modal').find('.modal-dialog').outerHeight(true);
        let marginModal = $('.img-enlarge-modal').outerHeight(true) - $('.img-enlarge-modal').outerHeight();
        let calcHeight = modalHeight - insertImg.outerHeight() + marginModal;
        insertImg.css('max-height', `calc(100vh - ${calcHeight}px)`);
        $('.img-enlarge-modal').css('visibility', 'visible');
    }, 200);

    $('.img-enlarge-modal').modal('show');

    // Remove modal html after close
    $('.img-enlarge-modal').on('hidden.bs.modal', function () {
        $(this).remove();
        $('.ui-dialog').focus();
    });
};

$(document).on('shown.bs.dropdown', '.dropdown', function () {
    // calculate the required sizes, spaces
    let $ul = $(this).children('.dropdown-menu');
    let $button = $(this).children('.dropdown-toggle');
    let ulOffset = $ul.offset();
    // how much space would be left on the top if the dropdown opened that direction
    let spaceUp = (ulOffset.top - $button.height() - $ul.height()) - $(window).scrollTop();
    // how much space is left at the bottom
    let spaceDown = $(window).scrollTop() + $(window).height() - (ulOffset.top + $ul.height());
    // switch to dropup only if there is no space at the bottom AND there is space at the top, or there isn't either but it would be still better fit
    if (spaceDown < 0 && (spaceUp >= 0 || spaceUp > spaceDown)) $(this).addClass('dropup');
}).on('hidden.bs.dropdown', '.dropdown', function () {
    // always reset after close
    $(this).removeClass('dropup');
});

/** @see SPL-3809 && SPL-4101 Date picker pops up when click in different place and fix pressing tab key */
let lastKeyUp = 0;
$(window).keyup((e) => {
    lastKeyUp = (e.keyCode ? e.keyCode : e.which);
});

$(document).on('focus', 'input:not(.tt-input):not(.input-ms-search)', function () {
    // Fix error with signup widget and error in FE test
    if ($('body#splynx-signup-widget') || process.env.NODE_ENV === 'test') {
        return false;
    }
    if (!$(this).is(':hover') && !(lastKeyUp == 9 || lastKeyUp == 16)) {
        lastKeyUp = 0;
        $(this).off('focus');
    }
});

// resize image after loading
window.resizeImage = function (element) {
    let image = $(element);
    if (image.width() >= image.height()) {
        image.height('100%');
    } else {
        image.width('100%');
    }
};

$(document).on('click', '.ticket-message-footer a.file_image', function (e) {
    e.preventDefault();
    window.imageTicketEnlarging($(this).find('img'));
});

window.numbersToRanges = function (ids) {
    let uniqueFilter = (value, index, self) => self.indexOf(value) === index;
    let sortNumber = (a, b) => a - b;
    ids = ids.filter(uniqueFilter);
    ids = ids.sort(sortNumber);

    let ranges = []; let rstart; let
        rend;
    for (let i = 0; i < ids.length; i++) {
        rstart = ids[i];
        rend = rstart;
        while (ids[i + 1] - ids[i] === 1) {
            rend = ids[i + 1]; // increment the index if the numbers sequential
            i++;
        }
        ranges.push(rstart === rend ? rstart : [rstart, rend]);
    }
    return ranges;
};

/**
 * Function for calucation popover placement
 * Usege exmple:
 *  $('table tbody').popover({
 *       placement: calculatePopoverPlacement,
 *  });
 */
window.calculatePopoverPlacement = function (context, source) {
    let sourceRect = source.getBoundingClientRect();
    let spaceBelow = window.innerHeight - sourceRect.bottom;

    if (spaceBelow < 420) {
        return 'auto-top';
    }
    return 'auto-bottom';
};

window.preventDubleClick = function (buttonElementForDisabled) {
    return {
        status: true,
        start() {
            if (this.status === false) {
                return false;
            }
            this.status = false;
            if (typeof buttonElementForDisabled !== 'undefined') {
                $(buttonElementForDisabled).attr('disabled', 'disabled');
            }
            return true;
        },
        end() {
            this.status = true;
            if (typeof buttonElementForDisabled !== 'undefined') {
                $(buttonElementForDisabled).removeAttr('disabled');
            }
        },
    };
};

(function () {
    let urls = {};
    let beforeSubmitDecorator = function (f, buttonElementForDisabled) {
        return function () {
            if (!(arguments[2].url in urls) && arguments[2].type && arguments[2].type.toLowerCase() === 'post') {
                if (typeof buttonElementForDisabled === 'string') {
                    urls[arguments[2].url] = window.preventDubleClick(buttonElementForDisabled);
                } else {
                    urls[arguments[2].url] = window.preventDubleClick();
                }
            }

            let preventFn = urls[arguments[2].url];
            this.preventDbUrl = arguments[2].url;
            if (!preventFn.start()) {
                return false;
            }

            let nativeCallResult = f.apply(this, arguments);
            // If send form canceled
            if (nativeCallResult === false) {
                preventFn.end();
            }

            return nativeCallResult;
        };
    };
    let completeDecorator = function (f) {
        return function (ajaxForm, status, form) {
            urls[this.preventDbUrl].end();
            // SPL-4736 if ajax form response false add data-form-changed = 1 for confirm changes modal
            let response = ajaxForm.responseJSON;
            if (isset(response, 'result') && response.result === false) {
                $(form).attr('data-form-changed', '1');
            }

            return f.apply(this, arguments);
        };
    };
    let ajaxFDecorator = function (f) {
        return function () {
            if (arguments[0].preventDb !== false) {
                if (arguments[0].beforeSubmit == undefined) {
                    // If beforeSubmit is not set
                    arguments[0].beforeSubmit = beforeSubmitDecorator(() => {
                    }, arguments[0].preventDb);
                } else {
                    arguments[0].beforeSubmit = beforeSubmitDecorator(arguments[0].beforeSubmit, arguments[0].preventDb);
                }

                if (arguments[0].complete == undefined) {
                    // If beforeSubmit is not set
                    arguments[0].complete = completeDecorator(() => {
                    });
                } else {
                    arguments[0].complete = completeDecorator(arguments[0].complete);
                }
            }
            return f.apply(this, arguments);
        };
    };
    $.fn.ajaxForm = ajaxFDecorator($.fn.ajaxForm);
}());

// Returns a function, that, as long as it continues to be invoked, will not
// be triggered. The function will be called after it stops being called for
// N milliseconds. If `immediate` is passed, trigger the function on the
// leading edge, instead of the trailing.
window.splynxDebounceFn = function (func, wait, immediate) {
    let timeout;
    return function () {
        let context = this; let
            args = arguments;
        let later = function () {
            timeout = null;
            if (!immediate) func.apply(context, args);
        };
        let callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
    };
};

/**
 * Get translation for message
 * @param string category
 * @param string message
 * @param array params
 * @returns string
 */
window.t = function (category, message, params) {
    if (typeof window.spl_translations_messages !== 'undefined'
        && category in window.spl_translations_messages
        && message in window.spl_translations_messages[category]) {
        message = window.spl_translations_messages[category][message];
    }

    if (!empty(params)) {
        for (let key in params) {
            message = message.replace(`{${key}}`, params[key]);
        }
    }
    return message;
};

window.go_address = function (address, anchor) {
    let go = function () {
        if (typeof anchor === 'undefined') {
            $.address.value(address);
        } else {
            $.address.value(`${address}#${anchor}`);
        }
    };

    return new Promise((resolve, reject) => {
        checkUnsavedDataBeforeLeavePage(() => {
            go();
            resolve();
        }, () => {
            reject();
        });
    });
};

window.addConfirmForUnsavedDataToSelect = function (select, contentContainer) {
    $(select).on('select2:selecting', (e) => {
        if (isHaveUnsavedData(contentContainer)) {
            e.preventDefault();
            $(select).select2('close');

            window.showUnsavedDataDialog(contentContainer, () => {
                $(select).val(e.params.args.data.id).trigger('change');
            });
        }
    });
};

window.isset = function (object, path) {
    let findPath;
    if (typeof object !== 'object' || object === null) {
        return false;
    }

    if (typeof path === 'number') {
        path = path.toString();
    }

    let parts;

    if (typeof path === 'string' || path instanceof String) {
        parts = [path];
    } else if (Array.isArray(path)) {
        parts = path;
    } else {
        return false;
    }

    findPath = function (object, path) {
        let first = path.shift().toString();
        if (object === null || typeof object !== 'object' || typeof object[first] === 'undefined') {
            return false;
        }

        if (path.length <= 0) {
            return true;
        }

        return findPath(object[first], path);
    };

    return findPath(object, parts);
};

window.formatFileSize = function (bytes, roundLength, kb_is, bits) {
    if (typeof roundLength === 'undefined') {
        roundLength = 2;
    }
    if (typeof kb_is === 'undefined') {
        kb_is = window.spl_config.numberConfig.networking.size_of_kb;
    }
    if (typeof bits === 'undefined') {
        bits = false;
    }

    bytes = parseFloat(bytes);

    let types;
    let i;

    if (bits) {
        types = ['b', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb', 'Eb', 'Zb', 'Yb'];
    } else {
        types = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    }

    for (i = 0; bytes >= kb_is && i < (types.length - 1); i++) {
        bytes /= kb_is;
    }

    return `${bytes.toFixed(roundLength) / 1} ${types[i]}`;
};

window.formatInternetSpeed = function (bytes, roundLength, kb_is, bits) {
    return `${window.formatFileSize(bytes, roundLength, kb_is, bits)}ps`;
};

window.removeSpaces = (string) => string.replace(/\s+/g, ' ').trim();

window.initMentions = function (targetElement, mentionList, eventTarget) {
    let init = function () {
        window.deleteMention(mentionList);
        let getItemString = function (item) {
            if (item.original.type === 'team') {
                return item.original.name;
            }
            return `${item.original.name} - ${item.original.email} (${item.original.login})`;
        };

        let tribute = new Tribute({
            menuContainer: document.body,
            values: mentionList,
            lookup(item) {
                return item.search.replace(/ +(?= )/g, '').trim();
            },
            selectTemplate(item) {
                return window.removeSpaces(`<span class="fr-deletable fr-tribute"><a href="#" 
                               class="froala-mention-inserted"
                               title="${getItemString(item).trim()}"
                               data-id="${item.original.id.trim()}"
                               data-type=${item.original.type.trim()}
                            >${item.original.name.trim()}</a></span>`);
            },
            menuItemTemplate(item) {
                let typeLabel = `<span class="item-type">${item.original.type_label}</span>`;
                return `${getItemString(item)} ${typeLabel}`;
            },
        });
        window.editorTribute = tribute;
        if (window.tributeAttached) {
            tribute.detach(targetElement);
        }
        tribute.attach(targetElement);
        window.tributeAttached = true;
        window.mentionsNeedsUpdating = false;

        eventTarget.on('keydown', (event) => {
            if (event.which === 13 && tribute.isActive) {
                return false;
            }
        }, true);

        targetElement.on('tribute-replaced', () => {
            window.disableEditorReinit = true;
        });
    };

    if (typeof Tribute === 'undefined') {
        $.getScript('/js/development/tribute.min.js', init);
    } else {
        init();
    }
};

window.updateMentions = function (editor, mentionsList) {
    if (!window.editorTribute) {
        return false;
    }
    window.editorTribute.detach(editor.$el);
    setTimeout(() => {
        window.initMentions(editor.$el, mentionsList, editor.events);
    }, 250);
};

window.getMentions = function (url, data) {
    if (!window.getMentionsIsRun) {
        window.getMentionsIsRun = true;
        $.ajax({
            dataType: 'json',
            url,
            data,
            success(result) {
                window.mentionsList = result;
                window.mentionsNeedsUpdating = true;
                window.getMentionsIsRun = false;
                window.updateMentions(window.globalEditor, window.mentionsList);
            },
        });
    }
};

window.deleteMention = function (mentions) {
    let editor = window.globalEditor.$el;
    let tmpMentions = [...mentions];
    editor.find('.froala-mention-inserted').each(function () {
        let id = $(this).data('id');
        let type = $(this).data('type');
        let mentionIsset = false;
        tmpMentions.forEach((val) => {
            if (val.id == id && val.type == type) {
                mentionIsset = true;
                return true;
            }
        });
        if (!mentionIsset) {
            $(this).remove();
        }
    });
    window.globalEditor.selection.save();

    // // @see https://jira.splynx.com/browse/SPL-8437?focusedCommentId=27481&page=com.atlassian.jira.plugin.system.issuetabpanels:comment-tabpanel#comment-27481
    let nameElement = '#customers_view_tabs > ul > li.x-tab.active.active-tab-item > a.active_tab.tabs__link';
    if ($(nameElement).length > 0 && $(nameElement)[0].hasChildNodes()) {
        let childrenElement = $(nameElement)[0].childNodes;
        if (childrenElement.length > 1) {
            let arrayChildElement = [];
            for (let i = 0; i < childrenElement.length; ++i) {
                arrayChildElement.push(childrenElement[i].nodeValue);
            }
            $(nameElement).text(arrayChildElement.join(''));
        }
    }

    window.globalEditor.html.set(editor.html());
};

window.initResponsiveTable = function (tableId) {
    $(`#${tableId}_wrapper .dataTables_scrollHead`).css({
        'overflow-x': 'auto',
    }).on('scroll', function () {
        let scrollBody = $(this).parent().find('.dataTables_scrollBody').get(0);
        scrollBody.scrollLeft = this.scrollLeft;
    });

    setTimeout(() => {
        $(`#${tableId}`).dataTable().api().draw();
    }, 0);

    $(window).resize(() => {
        $(`#${tableId}`).dataTable().api().draw();
    });

    // Redraw table for fix columns width
    let handleChangeSelect = function () {
        setTimeout(() => {
            $(`#${tableId}`).dataTable().api().draw();
        }, 0);
    };
    $('.select-column-input').off('change', handleChangeSelect).change(handleChangeSelect);

    $(`#${tableId}`).on('draw.dt', () => {
    // Fix duplicating selects when use scroll-x
        setTimeout(() => {
            $(`#${tableId}_wrapper .dataTables_scrollBody .select-column-input`).remove();
        }, 0);
    });
};

/**
 * Update data in select2 widget
 * @param string id Id of select2 element
 * @param object items
 * New data for widget in format:
 * {
 *     "simple_element_value": "simple_element_label",
 *     "group_label": {
 *         "group_element_value": "group_element_label",
 *     }
 * }
 */
window.updateSelect2Items = function (id, items) {
    let preparedSelectData = [];

    for (let k in items) {
        if (typeof items[k] === 'object') {
            let groupItem = {
                text: k,
                children: [],
            };
            for (let childrenId in items[k]) {
                groupItem.children.push({
                    id: childrenId,
                    text: items[k][childrenId],
                });
            }

            preparedSelectData.push(groupItem);
        } else {
            preparedSelectData.push({
                id: k,
                text: items[k],
            });
        }
    }

    $(`#${id}`).html('').select2({ data: preparedSelectData }).trigger('change');
};

// convert rgba color to rgb hex
window.rgbaToRgb = function (rgba) {
    let colors = rgba.split(',');
    let red = parseInt(colors[0].replace(/rgba\(/, ''), 10);
    let green = parseInt(colors[1], 10);
    let blue = parseInt(colors[2], 10);
    let alpha = colors[3].replace(/\)/, '').trim();
    let alpha2 = 1 - alpha;

    red = Math.round((alpha * (red / 255) + alpha2) * 255);
    green = Math.round((alpha * (green / 255) + alpha2) * 255);
    blue = Math.round((alpha * (blue / 255) + alpha2) * 255);

    return window.rgbToHex(red, green, blue);
};

// Convert hex rgb color to hsl
// recieve string in format #fff
// returns object
window.rgbToHSL = function (rgb) {
    // strip the leading # if it's there
    rgb = rgb.replace(/^\s*#|\s*$/g, '');

    // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
    if (rgb.length == 3) {
        rgb = rgb.replace(/(.)/g, '$1$1');
    }

    let red = parseInt(rgb.substr(0, 2), 16) / 255;
    let green = parseInt(rgb.substr(2, 2), 16) / 255;
    let blue = parseInt(rgb.substr(4, 2), 16) / 255;

    let colorMax = Math.max(red, green, blue);
    let colorMin = Math.min(red, green, blue);
    let delta = colorMax - colorMin;
    let l = (colorMax + colorMin) / 2;
    let h = 0;
    let s = 0;

    if (delta == 0) {
        h = 0;
    } else if (colorMax == red) {
        h = 60 * (((green - blue) / delta) % 6);
    } else if (colorMax == green) {
        h = 60 * (((blue - red) / delta) + 2);
    } else {
        h = 60 * (((red - green) / delta) + 4);
    }

    if (delta == 0) {
        s = 0;
    } else {
        s = (delta / (1 - Math.abs(2 * l - 1)));
    }

    return {
        h,
        s,
        l,
    };
};

// Convert HSL to RGB color
// Expects an object, returns a string with color in hex format like #ffffff
window.hslToRGB = function (hsl) {
    let { h } = hsl;
    let { s } = hsl;
    let { l } = hsl;
    let c = (1 - Math.abs(2 * l - 1)) * s;
    let x = c * (1 - Math.abs(((h / 60) % 2) - 1));
    let m = l - c / 2;
    let r;
    let g;
    let b;

    if (h < 60) {
        r = c;
        g = x;
        b = 0;
    } else if (h < 120) {
        r = x;
        g = c;
        b = 0;
    } else if (h < 180) {
        r = 0;
        g = c;
        b = x;
    } else if (h < 240) {
        r = 0;
        g = x;
        b = c;
    } else if (h < 300) {
        r = x;
        g = 0;
        b = c;
    } else {
        r = c;
        g = 0;
        b = x;
    }

    r = window.normalize_rgb_value(r, m);
    g = window.normalize_rgb_value(g, m);
    b = window.normalize_rgb_value(b, m);

    return window.rgbToHex(r, g, b);
};

window.normalize_rgb_value = function (color, m) {
    color = Math.floor((color + m) * 255);
    if (color < 0) {
        color = 0;
    }
    return color;
};

// Convert reg, green and blue components in hex rgb color
window.rgbToHex = function (r, g, b) {
    return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
};

// Makes color lighter or darker
// color - color in hex rgb
// percentage - percentage of changing lightness. Lighter if positive
window.lightenDarkenColor = function (color, percentage) {
    let hsl = window.rgbToHSL(color);
    let newLightness = hsl.l + (percentage / 100);
    if (newLightness > 1) {
        newLightness = 1;
    } else if (newLightness <= 0) {
        newLightness = 0;
    }
    hsl.l = newLightness;
    return window.hslToRGB(hsl);
};

/* Get the contrasting color for any hex color
 * @param  {String} A hexcolor value
 * @return {String} The contrasting color (colorBlack or colorWhite)
 */
window.makeColorContrast = function (hexcolor, colorBlack, colorWhite) {
    // If a leading # is provided, remove it
    if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
    }

    // If a three-character hexcode, make six-character
    if (hexcolor.length === 3) {
        hexcolor = hexcolor.split('').map((hex) => hex + hex).join('');
    }

    // Convert to RGB value
    let r = parseInt(hexcolor.substr(0, 2), 16);
    let g = parseInt(hexcolor.substr(2, 2), 16);
    let b = parseInt(hexcolor.substr(4, 2), 16);

    // Get YIQ ratio
    let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

    // Check contrast
    return (yiq >= 128) ? colorBlack : colorWhite;
};

$(document).on('change', '.input-group-file :file', function () {
    let input = $(this);
    let numFiles = input.get(0).files ? input.get(0).files.length : 1;
    let label = input.val().replace(/\\/g, '/').replace(/.*\//, '');

    let text_input = $(this).parents('.input-group').find(':text');
    let log = numFiles > 1 ? `${numFiles} files selected` : label;

    if (text_input.length) {
        text_input.val(log);
    }
});

window.formatSelect2Data = function (array) {
    let data = [];
    $.each(array, (key, value) => {
        if (typeof value == 'string') {
            data.push({
                text: value,
                id: key,
            });
        } else {
            let childrenArray = [];
            $.each(value, (key, value) => {
                childrenArray.push({
                    text: value,
                    id: key,
                });
            });

            data.push({
                text: key,
                id: 0,
                children: childrenArray,
            });
        }
    });

    return data;
};

window.arrayIncludes = function (array, needle) {
    for (let index in array) {
        if (array[index] === needle) {
            return true;
        }
    }
    return false;
};

// With fix Converting circular structure to JSON
window.jsonStringify = function (data) {
    // Note: cache should not be re-used by repeated calls to JSON.stringify.
    let cache = [];
    let json = JSON.stringify(data, (key, value) => {
        if (typeof value === 'object' && value !== null) {
            // Duplicate reference found, discard key
            if (window.arrayIncludes(cache, value)) {
                return;
            }
            // Store value in our collection
            cache.push(value);
        }
        return value;
    });
    cache = null;
    return json;
};

window.saveDataInCodeEditor = function (e, editor, cmd) {
    if (cmd == 'html') {
        if (editor.codeView.isActive()) {
            let mirror = editor.$box.find('.CodeMirror')[0].CodeMirror;
            mirror.on('change', () => {
                window.codeEditorHtml = editor.codeView.get();
            });
        }
    }
};

// Fix for prevent click on mention
$(document).on('click', '.froala-mention-inserted', (event) => {
    event.preventDefault();
});

window.actionsOnView = function (model) {
    return {
        refresh: () => {
            splynx_event_bus.emit(`refresh-view-actions-${model}`);
        },
        close: () => {
            splynx_event_bus.emit(`close-view-modal-${model}`);
        },
    };
};

function humanizeString(string) {
    if (typeof string === 'undefined') {
        return '';
    }

    if (typeof string !== 'string') {
        string = string.toString();
    }

    let matchedString = string.match(/([A-Z]+)v([0-9]+)|([A-Z][a-z]*)|[0-9]/g);

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    function isUpperCaseCharacter(string) {
        return string.length === 1 && string === string.toUpperCase();
    }

    let resultString = '';
    let wordBefore = '';
    for (let workKey in matchedString) {
        if (isNumeric(matchedString[workKey])) {
            if (!isNumeric(wordBefore)) {
                resultString += ` ${matchedString[workKey]}`;
            } else {
                resultString += matchedString[workKey];
            }
        } else {
            if (!Object.prototype.hasOwnProperty.call(matchedString, workKey)) {
                continue;
            }

            if (isUpperCaseCharacter(matchedString[workKey]) && isUpperCaseCharacter(wordBefore)) {
                resultString += matchedString[workKey];
            } else {
                resultString += ` ${matchedString[workKey]}`;
            }
        }

        wordBefore = matchedString[workKey];
    }

    return resultString.trim();
}

$(document).on('paste', '.tt-input', function () {
    let element = $(this);
    setTimeout(() => {
        element.trigger('keypress');
    }, 0);
});

window.openTab = function (tabContainerId, tabDataId) {
    $(`#${tabContainerId} li`).each(function (index) {
        if ($(this).attr('data-id') == tabDataId) {
            $(`#${tabContainerId}`).tabs('option', 'active', index);
        }
    });
};

window.scrollToComment = function (elementToFocus) {
    elementToFocus = $(elementToFocus);

    if (elementToFocus.length) {
        $('.focused-comment').removeClass('focused-comment');
        elementToFocus.addClass('focused-comment');

        let parent = elementToFocus.parent();
        let scrollHeight = elementToFocus.offset().top - parent.offset().top + parent.scrollTop() - parseInt(elementToFocus.css('margin-top'));

        let tabParent = elementToFocus.closest('div[role=tabpanel]');
        let parentTabScrollHeight = tabParent.offset().top;
        if (window.matchMedia('(min-width: 813px)').matches) {
            parentTabScrollHeight -= $('.spl-sticky-sidebar').height();
        }

        $('html, body').animate({ scrollTop: parentTabScrollHeight }, 'slow');
        parent.animate({ scrollTop: scrollHeight }, 'slow');
    }
};

$(document).on('click', '.copy-to-clipboard', function () {
    let input = $(this).closest('.clipboard-copy-wrapper').find('.for-copy');
    input.select();
    document.execCommand('copy');
    show_success($(this).data('success'), 5);
    if ($(this).data('callback')) {
        eval($(this).data('callback'));
    }
});

/*
    Init filter widget
    options format:
    {
        name: 'unique_filter_name',
        advanced: true, // If this filter is advanced (with right side filter)
        advancedEl: '#filter_wrapper_id',
        dtId: 'table_id',
        url: '/url/to/load/data',
        method: 'GET',
        reloadAfterChange: true, // Whether need reload table when change some of filter input
        remindFilters: true, // Whether need save filter state (in local storage)
        applyAtInit: true, // If need apply filter after loading page

        // If true then at first time filter emit 'manual-init' event on table
        // Otherwise will be used ajax.reload()
        // It need for delayed table init (basically when server side is enabled)
        initDTByEvent: false,

        // Filter buttons
        // Format:
        //{
        //        'btn_name': {
        //            id: 'btn_html_id',
        //            text: 'Button label',
        //            submit: true, // If true, then filter will be applied when this button is pressed
        //            reset: false, // If true, then this button will be reset filter
        //            visible: true, // Show/Hide
        //            name: 'btn-name', // Button name attribute
        //            value: 'value', // Button value attribute
        //            class: ['btn', 'btn-sm', 'btn-danger'], // Custom button classes
        //            options: { // Custom HTML attributes
        //                'data-my-attr': '',
        //            },
        //            handler() {
        //                // Handle button click
        //            },
        //            dropdown: false, // This is dropdown button?
        //            dropdownClass: [], // Custom dropdown <ul> tag classes
        //            buttons: { // Dropdown menu buttons. Parameter dropdown should be true for use it.
        //                add: {
        //                    id: 'add_btn',
        //                    text: 'Add item',
        //                    handler() {
        //                        // Handle 'add' button click
        //                    },
        //                },
        //            },
        //        },
        //    }
        //}
        // OR it can be function which return buttons object
        buttons: {},

        // Filter inputs
        // Format:
        //{
        //    filter_name: { // Name of filter will be used in request
        //        label: 'Filter label',
        //        type: 'select', // Type of input. List of types: date, date-range, datetime, hidden, multiple-select, number, password, select, text, textarea.
        //        default: '0', // Default value of input. This value used during reset filter and as initial value.
        //        options: {
        //            options: {0: 'All', 1: 'New'}, // Options of select and multiple-select,
        //            id: 'input_id',
        //            class: 'custom-input-class',
        //            // Other HTML attributes
        //        },
        //    },
        //}
        // OR it can be function which return filters object
        filters: {},

        // The same as filters parameter, but for right side filter
        advancedFilters: {},

        // Custom request params
        requestAdditionalParams: {
            list: 'yes',
        },

        customApply() {
            // Custom apply logic
        },

        mounted() {
            // Filter mounted() method
        },

        destroyed() {
            // Filter destroyed() method
        },
    }
 */
window.filterWidget = function (options) {
    let defaultOptions = {
        name: '',
        advanced: false,
        advancedEl: '',
        buttons: {},
        filters: {},
        advancedFilters: {},
        requestAdditionalParams: {},
        dtId: '',
        url: '',
        method: 'GET',
        initDTByEvent: false,
        reloadAfterChange: false,
        remindFilters: false,
        customApply: null,
        applyAtInit: false,
        mounted: null,
        watchUrlFilters: false,
        beforeWatchUrlCallback: null,
        destroyed: null,
    };
    let advancedApp;

    options = $.extend(true, defaultOptions, options);

    if (options.advanced) {
        advancedApp = new Vue({
            el: options.advancedEl,
            render(h) {
                return h('dt-advanced-filter', {
                    ref: 'app',
                    props: {
                        name: options.name,
                        filters: options.advancedFilters,
                    },
                });
            },
        });
    }

    let buttons = typeof options.buttons === 'function' ? options.buttons() : options.buttons;
    let filters = typeof options.filters === 'function' ? options.filters() : options.filters;

    let defaultButtons = {
        show: {
            text: t('common', 'Show'),
            submit: true,
        },
    };

    if (buttons !== false) {
        buttons = $.extend(true, defaultButtons, buttons);
    } else {
        buttons = {};
    }

    let methods = {
        addDataToDTRequest(data) {
            return this.$refs.app.addDataToDTRequest(data);
        },
        beforeWatchUrlCallback() {
            let self = this;
            return (filters) => {
                if (typeof options.beforeWatchUrlCallback === 'function') {
                    return options.beforeWatchUrlCallback.call(this, self, filters);
                }

                return filters;
            };
        },
    };

    if (typeof options.methods !== 'undefined') {
        methods = Object.assign(options.methods, methods);
    }

    let simpleApp = new Vue({
        el: options.el,
        data() {
            return {
                buttons,
                filters,
                dtId: options.dtId,
                url: options.url,
            };
        },
        mounted() {
            if (options.mounted !== null && typeof options.mounted === 'function') {
                options.mounted.call(this);
            }
        },
        destroyed() {
            if (options.destroyed !== null && typeof options.destroyed === 'function') {
                options.destroyed.call(this);
            }
        },
        methods,
        render(h) {
            return h('dt-filter', {
                ref: 'app',
                props: {
                    name: options.name,
                    filters: this.filters,
                    buttons: this.buttons,
                    dtId: this.dtId,
                    url: this.url,
                    method: options.method,
                    initDTByEvent: options.initDTByEvent,
                    reloadAfterChange: options.reloadAfterChange,
                    remindFilters: options.remindFilters,
                    advanced: options.advanced,
                    advancedVm: options.advanced ? advancedApp : null,
                    customApply: options.customApply,
                    requestAdditionalParams: options.requestAdditionalParams,
                    applyAtInit: options.applyAtInit,
                    watchUrlFilters: options.watchUrlFilters,
                    beforeWatchUrlCallback: this.beforeWatchUrlCallback(),
                },
            });
        },
    });

    return {
        app: simpleApp,
        advanced: typeof advancedApp !== 'undefined' ? advancedApp : null,
    };
};

/*
    Add filter data to Datatable request data object
    Used when server side enabled in DT.
    Example usage:
       ajaxUrl: {
            url: '',
            type: 'POST',
            data: 'modifyDTRequestData(someYourFilterAppToGetData)',
       },
 */
window.modifyDTRequestData = function (filterApp) {
    return (data) => {
        if (typeof filterApp !== 'undefined') {
            return filterApp.addDataToDTRequest(data);
        }

        return data;
    };
};

window.initPhotoSwiper = function () {
    if (typeof makePhotoSwiper === 'undefined') {
        $.getScript('/js/development/head.min.js', () => {
            let scripts = [
                '/js/development/splynx_photo_swiper.min.js',
            ];

            head.load(scripts, () => {
                window.makePhotoSwiper();
            });
        });
    } else {
        window.makePhotoSwiper();
    }
};

// Check the order of days when the static day is on
window.analysisReminderDay = function (day1, day2, day3, button = '') {
    let days = [Number(day1), Number(day2), Number(day3)];
    for (let i = 0; i < 3; i++) {
        let zeroIndex = days.indexOf(0);
        if (zeroIndex != -1) {
            days.splice(zeroIndex, 1);
        }
    }
    let compareDays = [];
    for (let key in days) {
        compareDays[key] = days[key];
    }
    compareDays = compareDays.sort((a, b) => a - b).filter((item, pos, ary) => !pos || item != ary[pos - 1]);
    if (compareDays.length == days.length && compareDays.every((v, i) => v === days[i])) {
        if (button.length != 0) {
            button.prop('disabled', false);
        }
        return true;
    }
    showErrors([t('common', '"Reminder #N day" should be in ascending order.')], 4);
    if (button.length != 0) {
        button.prop('disabled', true);
    }
};

// Checking if an element is at the bottom of the screen
window.isElementInBottom = (element) => {
    const viewportHeight = window.innerHeight;
    const scrollTop = window.scrollY;
    const elementOffsetTop = element.offset().top;

    const distance = scrollTop + viewportHeight - elementOffsetTop;
    const percents = Math.round(
        distance / ((viewportHeight + 32) / 100),
    );
    return percents < 35;
};

window.isElementInViewport = (element) => {
    if (typeof jQuery === 'function' && element instanceof jQuery) {
        element = element.get(0);
    }
    let rect = element.getBoundingClientRect();
    return (
        rect.top >= 0
        && rect.left >= 0
        && rect.bottom <= $(window).height()
        && rect.right <= $(window).width()
    );
};

// Dropdown position fix
window.fixDropDownPosition = function (htmlElement) {
    let multiple = $(htmlElement).data().multipleSelect;
    let choise = multiple.$choice;
    let dropdown = multiple.$drop;

    // Define bottom or top position
    if (window.isElementInBottom($(choise))) {
        dropdown.removeClass('bottom');
        dropdown.addClass('top');
    } else {
        dropdown.removeClass('top');
        dropdown.addClass('bottom');
    }
};

window.removeWebUiPopovers = function () {
    let popovers = document.querySelectorAll('.webui-popover.out, .webui-popover.in, .popover.show');
    popovers?.forEach((popover) => {
        popover.remove();
    });
};

$(document).on('click', '.partially-hidden-block .show-all', (el) => {
    $(el.target).parent().removeClass('partially-hidden-block');
    $(el.target).remove();
});

window.floatElementsRemover = () => {
    const isElementFloat = (node) => node.classList.contains('webui-popover') || (node.getAttribute('role') === 'dialog' && node.getAttribute('class') !== 'pswp');
    // check added in order to fix the error in iframe
    // @see https://jira-splynx.atlassian.net/browse/SPL-8728
    if (typeof window.splynx_event_bus !== 'undefined') {
        splynx_event_bus.on('before_switch_page', () => {
            Array.from(document.body.children).forEach((node) => {
                if (isElementFloat(node)) {
                    node.outerHTML = '';
                }
            });
        });
    }
};
window.floatElementsRemover();

window.RealNumber = class RealNumber {
    constructor(number, initialization) {
        this.integerPart = initialization?.integerPart || 0;
        this.decimalPart = initialization?.decimalPart || 0;
        this.thousandSeparator = initialization?.thousandSeparator || '';
        this.decimalSeparator = initialization?.decimalSeparator || '.';
        this.float = initialization?.float || 0;
        if (number === null || number === undefined) {
            number = 0;
        }
        if (typeof number === 'string') {
            if (Number.isNaN(parseFloat(number))) {
                number = 0;
            } else {
                number = this.removeSeparators(number);
            }
        }
        this._stringFormat = '0';
        this._numberFormat = 0;
        this.splitParts(number);
        this.stringFormat(number);
        this.numberFormat();
    }

    removeSeparators(value) {
        if (!this.float) {
            return value.replaceAll(this.thousandSeparator, '');
        }
        value = value.split('');
        let isBeenLast;
        value.forEach((symbol, index) => {
            if (/[^0-9]/.test(symbol) && symbol !== '-') {
                value[index] = '';
                isBeenLast = index;
            }
        });
        if (isBeenLast) {
            value[isBeenLast] = '.';
        }
        return value.join('');
    }

    splitParts(number) {
        this.decimalPart = (parseFloat(number) - parseInt(number)).toFixed(this.float);
        this.integerPart = parseInt(parseInt(number) + parseFloat(this.decimalPart)).toString();
    }

    stringFormat(number) {
        if (typeof number === 'string') {
            if (this.integerPart === '0' && number[0] === '-') {
                this.integerPart = '-0';
            }
        } else if (this.integerPart === '0' && number < 0) {
            this.integerPart = '-0';
        }
        let integer = parseInt(this.integerPart).toLocaleString('en-US').replaceAll(',', this.thousandSeparator);
        let decimal = this.decimalPart.replace('0', '');
        if (parseInt(decimal) === 1) {
            this._stringFormat = integer + Number(0).toFixed(this.float).replace('0.', '.');
            return true;
        }
        this._stringFormat = integer + decimal.replace('.', this.decimalSeparator).replace('-', '');
    }

    numberFormat() {
        if (parseFloat(this.decimalPart) < 0) {
            this.decimalPart = Math.abs(parseFloat(this.decimalPart)).toString();
        }
        let number = parseFloat(this.integerPart + this.decimalPart);
        let decimal;
        if (number >= 0) {
            decimal = number - parseInt(number);
            this._numberFormat = parseFloat(parseFloat(parseInt(this.integerPart) + decimal).toFixed(this.float));
        } else {
            decimal = Math.abs(number) - Math.abs(parseInt(number));
            this._numberFormat = parseFloat(parseFloat(parseInt(this.integerPart) - decimal).toFixed(this.float));
        }
    }
};

window.stripTagsFromString = (string) => {
    string = `<div>${string}</div>`;
    let temporaryText = '';
    $(string).contents().each(function () {
        temporaryText += `${$(this).text()} `;
        if (!$(this).text()) {
            temporaryText += ' ';
        }
    });
    return temporaryText.replace(/ +(?= )/g, '');
};

if (!String.prototype.replaceAll) {
    /* eslint-disable-next-line no-extend-native */
    String.prototype.replaceAll = function (find, replace) {
        return this.replace(new RegExp(find, 'g'), replace);
    };
}

window.isValidJSON = (string) => {
    try {
        JSON.parse(string);
    } catch (e) {
        return false;
    }
    return true;
};

window.clearAllTimeouts = function () {
    let id = window.setTimeout(() => {
    }, 0);
    while (id--) {
        window.clearTimeout(id);
    }
};

window.closeAllWebuiPopoverByCustomClass = function (className) {
    // The library prefixes the class name with `webui-popover-` by default
    $(`.webui-popover-${className}`).each(function () {
        $(this).data('trigger-element').webuiPopover('destroy');
    });
};

window.initTabs = (selector, options = {}) => {
    let createCallback;
    let beforeActivateCallback;
    if (Object.prototype.hasOwnProperty.call(options, 'create')) {
        createCallback = options.create;
        delete options.create;
    }

    if (Object.prototype.hasOwnProperty.call(options, 'beforeActivate')) {
        beforeActivateCallback = options.beforeActivate;
        delete options.beforeActivate;
    }

    $(selector).tabs({
        create: (event, ui) => {
            $(`${selector}>ul>li`).each((index, element) => {
                let link = $(element).find('a');
                let href = link.attr('href');
                if (href !== undefined && href[0] !== '#') {
                    let id = link.text().toLowerCase().replace(/[^a-zA-Z0-9]/g, '_').replace(/_{2,}/g, '_');
                    link.attr('href', `#${id}`);
                    $(`#${$(element).attr('aria-controls')}`).remove();
                    $(selector).append(`<div id='${id}' data-href='${href}' data-loaded='false'><div class='main-tab-holder ps-20 pe-20'>${t('common', 'Loading...')}</div></div>`);
                    if ($(element).attr('aria-selected') === 'true') {
                        window.loadTab($(`#${id}`));
                    }
                } else {
                    $(`#${$(element).attr('aria-controls')}`).data('loaded', true);
                }
            });

            $(selector).tabs('refresh');

            if (!Object.prototype.hasOwnProperty.call(options, 'active') && window.location.hash) {
                let index = $(`${selector}>div`).index($(window.location.hash));
                $(selector).tabs('option', 'active', index > 0 ? index : 0);
            }

            if (typeof createCallback === 'function') {
                createCallback(event, ui);
            }
        },
        beforeActivate: (event, ui) => {
            window.loadTab(ui.newPanel);

            if (typeof beforeActivateCallback === 'function') {
                beforeActivateCallback(event, ui);
            }
        },
        ...options,
    });
};

window.loadTab = (panel) => {
    if (!panel.data('loaded')) {
        $.get(panel.data('href'))
            .done((data) => {
                panel.html(data);
                panel.data('loaded', true);
            })
            .fail(() => {
                panel.html(`<div class="main-tab-holder ps-20 pe-20">${t('common', "Couldn't load!")}</div>`);
            });
    }
};

$(window).on('select2:open', () => {
    setTimeout(() => {
        let searchInput = $('.select2-search--dropdown input.select2-search__field');
        if (searchInput.length) {
            searchInput.get(0).focus();
        }
    }, 0);
});

// Only in horizontal plane. Could be modified for use in vertical plane.
window.getElementOffsets = (element) => {
    let elementLeft = $(element).offset().left;
    let elementRight = elementLeft + $(element).width();

    return {
        left: elementLeft,
        right: elementRight,
    };
};

// Only in horizontal plane. Could be modified for use in vertical plane.
window.isElementVisibleInScrollableArea = (area, element) => {
    let elementOffsets = window.getElementOffsets($(element));
    let areaOffsets = window.getElementOffsets($(area));

    return elementOffsets.left > areaOffsets.left && elementOffsets.right < areaOffsets.right;
};

// Only in horizontal plane. Could be modified for use in vertical plane.
window.scrollAreaToElement = (area, element) => {
    let elementOffsets = window.getElementOffsets($(element));
    let areaOffsets = window.getElementOffsets($(area));

    let scrollLeft = 0;
    if (elementOffsets.right > areaOffsets.right) {
        scrollLeft = elementOffsets.right - areaOffsets.right + $(area).scrollLeft() + 10;
    } else {
        scrollLeft = elementOffsets.left - areaOffsets.left + $(area).scrollLeft() - 10;
    }

    $(area).animate({
        scrollLeft,
    }, 50);
};

window.toggleCustomersInfoBlock = (type) => {
    let toggleButton = $(`#admin_tickets_customer_info_show_all_${type}`);

    if (toggleButton.hasClass('collapsed')) {
        $(`#admin_tickets_customer_info_show_all_${type}_block`).removeClass('d-none');
        toggleButton.removeClass('collapsed');
    } else {
        $(`#admin_tickets_customer_info_show_all_${type}_block`).addClass('d-none');
        toggleButton.addClass('collapsed');
    }
};

window.domReplace = function (element, to, prepend = false) {
    let old = $(element);
    if (prepend) {
        old.clone(true).prependTo($(to));
    } else {
        old.clone(true).appendTo($(to));
    }
    old.remove();
};

// Init all witgets on page
window.initWidgets = () => {
    makeSelect2();
    makeMultileSelect();
    makeDatepicker();
    makeDecimalInput();
    processPasswords();
    processDT();
    processCollapsedPanels();
    processValidationgInputs();
    initReportValidityBeforeSubmitForm();
    addBeforeSubmitValidation();
    makePopover();
};

window.setSeparators = () => {
    if (empty(window.spl_config)) {
        return false;
    }
    const config = window.spl_config.numberConfig;
    if (empty(config)) {
        return false;
    }
    switch (config.thousand_finance.separator) {
        case '0': config.thousand_finance.separator = ''; break;
        case '1': config.thousand_finance.separator = "'"; break;
        case '2': config.thousand_finance.separator = ','; break;
        case '3': config.thousand_finance.separator = '.'; break;
        default: config.thousand_finance.separator = ''; break;
    }
    switch (config.decimal_finance.separator) {
        case '0': config.decimal_finance.separator = '.'; break;
        case '1': config.decimal_finance.separator = ','; break;
        default: config.decimal_finance.separator = '.'; break;
    }
    window.spl_config.numberConfig = config;
};
window.setSeparators();
window.formLink = (href) => {
    if (!href) {
        return false;
    }
    return href.length > 0 ? href : false;
};
window.linkHandler = (action, withoutHref) => {
    let prevent = 'event.preventDefault(); ';
    if (withoutHref) {
        prevent = '';
    }
    try {
        return new Function('event', prevent.concat(action)).toString();
    } catch {
        return 'function() {}';
    }
};

// Froala Custom icons
window.setFroalaIcons = (defaultTemplate) => {
    $.FE.DefineIcon('bold', { NAME: 'icon-ic_fluent_text_bold_24_regular' });
    $.FE.DefineIcon('italic', { NAME: 'icon-ic_fluent_text_italic_24_regular' });
    $.FE.DefineIcon('underline', { NAME: 'icon-ic_fluent_text_underline_24_regular' });
    $.FE.DefineIcon('formatUL', { NAME: 'icon-ic_fluent_apps_list_24_regular' });
    $.FE.DefineIcon('formatOL', { NAME: 'icon-ic_fluent_text_number_list_ltr_24_regular' });
    $.FE.DefineIcon('insertLink', { NAME: 'icon-ic_fluent_link_square_24_regular' });
    $.FE.DefineIcon('linkList', { NAME: 'icon-ic_fluent_search_24_regular' });
    $.FE.DefineIcon('color', { NAME: 'icon-ic_fluent_color_24_regular' });
    $.FE.DefineIcon('insertTable', { NAME: 'icon-ic_fluent_table_24_regular' });
    $.FE.DefineIcon('insertImage', { NAME: 'icon-ic_fluent_image_add_24_regular' });
    $.FE.DefineIcon('clearFormatting', { NAME: 'icon-ic_fluent_eraser_24_regular' });
    $.FE.DefineIcon('remove', { NAME: 'icon-ic_fluent_eraser_24_regular' });
    $.FE.DefineIcon('html', { NAME: 'icon-ic_fluent_code_24_regular' });
    $.FE.DefineIcon('buttonIcon', { NAME: 'icon-ic_fluent_comment_checkmark_24_regular' });

    $.FroalaEditor.ICON_DEFAULT_TEMPLATE = defaultTemplate;
};

window.objectMap = function objectMap(object, mapFn) {
    return Object.keys(object).reduce((result, key) => {
        result[key] = mapFn(object[key]);
        return result;
    }, {});
};

window.snakeCase = (string) => string && string.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
)
    .map((string) => string.toLowerCase())
    .join('_');

window.copyElementToClipboardAsImageById = (id) => {
    if (typeof ClipboardItem === 'undefined') {
        return;
    }

    let element = document.getElementById(id);
    window.html2canvas(element, {
        onclone: (element) => {
            element.getElementById(id).parentElement.style.width = `${element.getElementById(id).scrollWidth}px`;
        },
        scale: 1.5,
    })
        .then((canvas) => {
            // eslint-disable-next-line
            canvas.toBlob((blob) => navigator.clipboard.write([new ClipboardItem({ 'image/png': blob })]));
            window.show_success(`${t('common', 'Result image copied to clipboard!')}`, 3);
        });
};
