<script>
import { isset } from '@/utils/functions';
import SplInputMixin from '../input-mixin';

export default {
    name: 'XTextField',
    mixins: [SplInputMixin],
    props: {
    // For v-model
        value: [String, Number],
        // This is custom attributes for input, like class or style etc.
        options: Object,
        // Custom button at right side of input. Such as clear button.
        button: {
            type: [Object, Boolean],
            default: false,
        },
    },
    computed: {
        attributes() {
            let attributes = this.options;
            if (typeof attributes === 'undefined') {
                attributes = {};
            }

            if (!isset(attributes, 'class')) {
                attributes.class = '';
            }

            attributes.class = this.addClass(attributes.class, ['form-control', 'input-sm']);

            return attributes;
        },
    },
    methods: {
        inputHandler(event) {
            this.$emit('input', event.target.value);
        },
    },
    render(h) {
        let proxy = this;
        let props = $.extend(true, {
            value: this.value,
        }, this.attributes);

        let classes = 'form-control input-sm';
        if (isset(this.attributes, 'class')) {
            classes = this.attributes.class;
            delete this.attributes.class;
        }

        let input = h('input', {
            ref: 'input',
            class: classes,
            attrs: {
                type: 'text',
                value: this.value,
            },
            domProps: props,
            on: {
                input: this.inputHandler,
                change: () => {
                    this.$emit('change');
                },
                invalid: (e) => {
                    if (typeof window.splynx_event_bus !== 'undefined') {
                        window.splynx_event_bus.emit('invalid', e);
                    }
                    this.$emit('invalid', e);
                },
            },
        });

        if (this.button === false) {
            return input;
        }

        let buttonProps = {
            id: this.button.id,
            style: 'cursor: pointer;',
            class: 'input-group-text',
        };

        let buttonClass = 'input-group-text';

        if (isset(this.button, ['options'])) {
            buttonProps = $.extend(true, buttonProps, this.button.options);
            if (isset(this.button, ['options', 'class'])) {
                buttonClass = this.button.options.class;
            }
        }

        let span = h('span', {
            class: buttonClass,
            ref: 'button',
            domProps: buttonProps,
            on: {
                click() {
                    if (isset(proxy.button, 'handler') && typeof proxy.button.handler === 'function') {
                        proxy.button.handler(proxy._self, proxy.$refs.input, proxy.$refs.button);
                    }
                },
            },
        }, [
            this.button.html ? function () {
                return this.button.html;
            } : h('i', {
                class: this.button.class,
            }),
        ]);

        return h('div', {
            class: {
                'input-group': true,
            },
        }, [
            input,
            span,
        ]);
    },
};
</script>
