<template>
    <div class="input-group">
        <button
            v-if="showPasswordButton"
            class="btn btn-outline-secondary btn-icon show-password"
            type="button"
            data-testid="visibilityButton"
            :title="buttonTitle"
            @click="togglePassword"
        >
            <i :class="buttonIcon" />
        </button>

        <input
            ref="passwordInput"
            type="text"
            data-testid="input"
            v-bind="attributesForVisible"
            autocomplete="off"
            :style="borderStyle"
        >
        <input
            ref="hiddenInput"
            v-bind="attributesForHidden"
            type="hidden"
        >

        <button
            v-if="generatePassword"
            class="btn btn-outline-secondary btn-icon generate-password"
            type="button"
            :title="$t('common', 'Generate password')"
            @click="generatePasswordHandler()"
        >
            <i class="icon-ic_fluent_lock_shield_24_regular" />
        </button>
    </div>
</template>

<script>
import { isset } from '@/utils/functions';
import MaskedInput from '@/utils/classes/MaskedInput';
import SplInputMixin from '../input-mixin';

export default {
    name: 'XInputPassword',
    mixins: [SplInputMixin],
    props: {
        options: {
            type: Object,
            default: () => ({}),
        },
        value: [String, Number],
        showPasswordButton: {
            type: Boolean,
            default: true,
        },
        generatePassword: {
            type: Boolean,
            default: true,
        },
        showGenerateConfirm: {
            type: Boolean,
            default: true,
        },
        sign: {
            type: String,
            default: '*',
        },
        generatePasswordUrl: {
            type: String,
            default: '/admin/tools--generate-password',
        },
    },
    data() {
        return {
            visible: false,
            maskedInput: null,
        };
    },
    mounted() {
        this.maskedInput = new MaskedInput(
            this.$refs.passwordInput,
            !this.visible,
            this.onChange,
            this.onVisibilityChanged,
            false,
        );
        this.maskedInput.setValue(this.value);
    },
    computed: {
        maskedClass() {
            return !this.visible ? MaskedInput.MASKED : '';
        },
        attributesForVisible() {
            let attributes = { ...this.options };
            if (!isset(attributes, ['class'])) {
                attributes.class = '';
            }

            attributes.class = this.addClass(attributes.class, [
                'password_input',
                'form-control',
                'input-sm',
                this.maskedClass,
            ]);
            delete attributes.id;
            delete attributes.name;

            return attributes;
        },
        attributesForHidden() {
            let attributes = { ...this.$attrs };
            if (isset(this.options, 'name')) {
                attributes.name = this.options.name;
            }
            if (isset(this.options, 'id')) {
                attributes.id = this.options.id;
            }

            return attributes;
        },
        buttonTitle() {
            return this.visible ? this.$t('common', 'Hide password') : this.$t('common', 'Show password');
        },
        buttonIcon() {
            return this.visible ? 'icon-ic_fluent_eye_off_24_regular' : 'icon-ic_fluent_eye_24_regular';
        },
        borderStyle() {
            if (this.generatePassword) {
                return {};
            }
            return {
                'border-top-right-radius': '4px',
                'border-bottom-right-radius': '4px',
            };
        },
    },
    methods: {
        onChange(dryValue) {
            this.$refs.hiddenInput.value = dryValue;
            this.$emit('input', dryValue);
        },
        onVisibilityChanged(isMask) {
            this.visible = !isMask;
        },
        onSymbolChange(newSymbol) {
            this.$emit('symbol-changed', newSymbol);
        },
        togglePassword() {
            this.maskedInput.toggleMaskState();
        },
        generatePasswordHandler() {
            if (!this.generatePassword) {
                return;
            }

            if (this.showGenerateConfirm && this.value !== '' && !window.confirm(t('config', 'Are you sure you want to generate a random password?'))) {
                return;
            }

            $.ajax({
                type: 'GET',
                url: this.generatePasswordUrl,
                dataType: 'json',
                success: (response) => {
                    this.maskedInput.setValue(response.string);
                    this.maskedInput.setMaskState(false);
                },
            });
        },
    },
    watch: {
        value(newValue) {
            if (newValue !== this.maskedInput.input.val()) {
                this.maskedInput.setValue(newValue);
            }
        },
    },
};
</script>
