<template>
    <div :class="classes">
        <span class="sr-only">Loading...</span>
    </div>
</template>

<script>
/**
 * @see See [Confluence](https://docs.dev.spl.co.ua/en/fecodewiki/base-components/x-bootstrap-spinner)
 */
export default {
    name: 'XBootstrapSpinner',
    props: {
        type: {
            type: String,
            default: 'primary',
        },
        size: {
            type: String,
            default: 'md',
        },
    },
    computed: {
        classes() {
            return ['b-spinner-loader', `text-${this.type}`, this.size];
        },
    },
};
</script>

<style scoped>
@keyframes spinner-border {
    to { transform: rotate(360deg); }
}

.b-spinner-loader {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}

.b-spinner-loader.sm {
    width: 1.5rem;
    height: 1.5rem;
}

.b-spinner-loader.md {
    width: 2rem;
    height: 2rem;
}

.b-spinner-loader.lg {
    width: 4rem;
    height: 4rem;
}

.text-primary {
    color: #007bff!important;
}

.text-secondary {
    color: #6c757d!important;
}

.text-success {
    color: #28a745!important;
}

.text-danger {
    color: #dc3545!important;
}

.text-warning {
    color: #ffc107!important;
}

.text-info {
    color: #17a2b8!important;
}

.text-light {
    color: #f8f9fa!important;
}

.text-dark {
    color: #343a40!important;
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0,0,0,0);
    white-space: nowrap;
    border: 0;
}
</style>
