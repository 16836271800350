<template>
    <div
        class="side"
        :class="[!isCollapsed ? 'expanded' : 'collapsed']"
        :style="{'position' : 'relative', 'width': sidebarWidth}"
        @mouseleave="onMouseLeave"
        @click="closeMobileMenu"
    >
        <div class="sidebar-menu-wrapper">
            <div class="enter">
                <slot />
            </div>
            <div class="sidebar-menu">
                <div class="menu">
                    <div class="menu-list">
                        <x-menu-item
                            v-for="(item, index) in filteredItems"
                            :key="index"
                            :item="item"
                            :first-in-group="firstInGroup"
                            :is-collapsed="isCollapsed"
                            :active-item="activeItem"
                            :opened-item="openedItem"
                            :opened-sub-item="openedSubItem"
                            :switch-page="switchPage"
                            :app-part="appPart"
                            @click-item="clickItem"
                            @activate-item="activateItem"
                            @set-mobile-item="setMobileItem"
                            @expand-item="expandItem"
                            @unset-mobile-item="unsetMobileItem"
                        />
                    </div>
                    <div
                        v-if="isCollapsed && mobileItem"
                        class="mobile-item"
                        :style="mobileItemStyle.item"
                    >
                        <x-menu-item
                            v-if="mobileItem"
                            :item="mobileItem"
                            :is-mobile-item="true"
                            :is-collapsed="isCollapsed"
                            :active-item="activeItem"
                            :opened-item="openedItem"
                            :opened-sub-item="openedSubItem"
                            :switch-page="switchPage"
                            @click-item="clickItem"
                            @expand-item="expandItem"
                            @activate-item="activateItem"
                        />
                    </div>

                    <div
                        :style="mobileItemStyle.dropdown"
                        class="menu-item-dropdown"
                    >
                        <template v-if="mobileItem && mobileItem.items">
                            <x-menu-item
                                v-for="(item, index) in mobileItem.items"
                                :key="index"
                                :item="item"
                                :active-color="mobileItem['menuGroup']"
                                :level="level+1"
                                :is-collapsed="isCollapsed"
                                :active-item="activeItem"
                                :opened-item="openedItem"
                                :opened-sub-item="openedSubItem"
                                :switch-page="switchPage"
                                @click-item="clickItem"
                                @expand-item="expandItem"
                                @activate-item="activateItem"
                            />
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { empty } from '@/utils/functions';
import { mapActions } from 'vuex';
import XMenuItem from './XMenuItem';

export default {
    name: 'XMenuApp',
    props: {
        menuStructure: Array,
        appPart: String,
        switchPage: String,
        collapsedMenu: {
            type: Boolean,
        },
    },
    data() {
        return {
            isCollapsed: this.collapsedMenu,
            width: '236px',
            widthCollapsed: '48px',
            activeItem: null,
            activeSearchItemLevel: 1,
            mobileItem: null,
            mobileItemPos: 0,
            mobileItemHeight: 0,
            mobileItemTimeout: null,
            parentHeight: '100%',
            parentWidth: '100vw',
            parentOffsetTop: '0px',
            parentOffsetLeft: '0px',
            resultSearchItem: null,
            parentItem: null,
            openedItem: null,
            openedSubItem: null,
            level: 1,
            items: this.menuStructure,
            activeBlocks: [],
            firstInGroup: [],
        };
    },
    created() {
        this.activateItem();
        add_socket_listener('updateMenuCounter', this.updateMenuCounterEvent);
        this.isBlockExist();
        window.xApp.menu = this;
    },
    mounted() {
        this.updateAdminCounters();
        this.updatePortalCounters();
    },
    computed: {
        filteredItems() {
            return this.items.filter((item) => item);
        },
        sidebarWidth() {
            return this.isCollapsed ? this.widthCollapsed : this.width;
        },
        mobileItemStyle() {
            return {
                item: [
                    { position: 'absolute' },
                    { top: `${this.mobileItemPos}px` },
                    { left: '0px' },
                    { 'z-index': 200 },
                    { width: `calc(${this.parentWidth} - ${this.parentOffsetLeft})` },
                    { 'max-width': this.width },
                    { height: '32px' },
                    { color: '#333' },
                ],
                dropdown: [
                    { position: 'absolute' },
                    { top: `calc(${this.mobileItemPos}px + 32px)` },
                    { left: this.sidebarWidth },
                    { right: '0px' },
                    { background: '#fff' },
                    { width: `calc(${this.width} - ${this.sidebarWidth})` },
                    { 'max-width': `calc(${this.width} - ${this.sidebarWidth})` },
                    { 'max-height': `calc(${this.parentHeight} - ${this.mobileItemPos + this.mobileItemHeight}px )` },
                    { 'overflow-y': 'auto' },
                ],
            };
        },
    },
    methods: {
        updateAdminCounters() {
            if (this.appPart === 'admin') {
                this.getMenuMessagesCounter();
            }
        },
        updatePortalCounters() {
            if (this.appPart === 'portal') {
                this.getPortalMenuMessagesCounter();
                this.getPortalMenuTicketsCounter();
                this.getPortalMenuNewsCounter();
            }
        },
        isBlockExist() {
            this.items.forEach((item) => {
                if (!this.activeBlocks.some((el) => this.checkMenuGroup(el, item))) {
                    this.firstInGroup.push(item.title);
                    this.activeBlocks.push(item.menuGroup);
                }
            });
        },
        checkMenuGroup(el, item) {
            return el === item.menuGroup;
        },
        getMenuMessagesCounter() {
            let $this = this;
            $.ajax({
                url: '/admin/support/inbox--unread-count',
                success(response) {
                    $this.updateMenuCounterEvent(response);
                },
            });
        },
        getPortalMenuMessagesCounter() {
            let $this = this;
            $.ajax({
                url: '/portal/messages--unread-amount',
                success(response) {
                    $this.updateMenuCounterEvent(response);
                },
            });
        },
        getPortalMenuTicketsCounter() {
            let $this = this;
            $.ajax({
                url: '/portal/tickets--unread-amount',
                success(response) {
                    $this.updateMenuCounterEvent(response);
                },
            });
        },
        getPortalMenuNewsCounter() {
            $.ajax({
                url: '/portal/news--unread-amount',
                success: (response) => {
                    this.updateMenuCounterEvent(response);
                },
            });
        },
        updateMenuCounterEvent(data) {
            if (data.controllers !== undefined) {
                this.updateMenuCounter(this.items, data.controllers, data.counter);
            }
        },
        updateMenuCounter(items, controllers, counter) {
            items.forEach((item, index) => {
                controllers.forEach((controller) => {
                    if (item.source === controller) {
                        Vue.set(items[index], 'counterValue', counter);
                    }
                });
                if (!empty(item.items)) {
                    this.updateMenuCounter(item.items, controllers, counter);
                }
            });
        },
        isEmptyLink(url) {
            return url === '#' || url === '';
        },
        itemCanBeOpened(item) {
            return !empty(item.items);
        },
        expandItem(item, level) {
            this.checkLinksLevel(item, level);
            this.activateItem(item);
        },
        clickItem(item, level, event) {
            const isNeedOpenNewTab = window.checkIfNeedOpenLinkInNewTab(event);
            if (isNeedOpenNewTab) {
                this.switchContent(item.url, isNeedOpenNewTab);
            } else {
                this.checkLinksLevel(item, level);
                this.switchContent(item.url, false);
                this.activateItem(item);
            }
        },
        checkLinksLevel(item, level) {
            if (!this.itemCanBeOpened(item) && level === 1) {
                this.openedItem = null;
                this.openedSubItem = null;
            } else if (this.itemCanBeOpened(item) && this.openedItem !== item && this.openedSubItem !== item) {
                this.setOpenItem(item);
            } else if (this.itemCanBeOpened(item) && level === 1) {
                this.openedItem = item;
            } else if (this.itemCanBeOpened(item) && level === 2) {
                this.openedSubItem = item;
            } else if (!this.itemCanBeOpened(item) && level === 2) {
                this.openedSubItem = null;
            }
        },
        activateItemPageSwitch(item) {
            this.activateItem(item);
        },
        activateItem(item) {
            let currentUrl = window.location.pathname + window.location.search;
            if (item !== null && item && (item.url === currentUrl || (!empty(item.urlAlternative) && item.urlAlternative === currentUrl))) {
                this.activeItem = item;
                return false;
            }
            this.searchItem(this.items, currentUrl, 1);

            if (empty(item) && !empty(this.activeItem)) {
                this.checkLinksLevel(this.activeItem, this.activeSearchItemLevel);
            }
        },
        setOpenItem(item) {
            if (this.openedItem === item) {
                this.openedItem = null;
                this.openedSubItem = null;
            } else if (this.openedSubItem === item) {
                this.openedSubItem = null;
            } else if (this.openedItem !== null && this.openedItem !== item && this.openedItem.items && this.openedItem.items.length > 0 && this.isChild(this.openedItem, item)) {
                this.openedSubItem = item;
            } else {
                this.openedItem = item;
            }
        },
        isChild(parent, child) {
            if (!child) return false;
            return parent.items.some((item) => {
                if (item === child) {
                    this.openedItem = parent;
                }
                return item === child;
            });
        },
        searchItem(arr, url, level) {
            // check if it is entry point url, check if not adding additional field for module
            if (url && url.indexOf('?module=') !== -1 && url.indexOf('admin/config/additional-fields') === -1) {
                this.searchItemForEntryPoint(arr, url, level);
                return;
            }

            arr.forEach((item) => {
                let found = false;
                if (url && url.indexOf(item.url) !== -1) {
                    found = true;
                } else if (!empty(item.urlAliases)) {
                    for (let key in item.urlAliases) {
                        if (url && url.indexOf(item.urlAliases[key]) !== -1) {
                            found = true;
                            break;
                        }
                    }
                }

                if (found) {
                    this.parentItem = this.resultSearchItem;
                    this.activeItem = item;
                    if (level !== undefined) {
                        this.activeSearchItemLevel = level;
                    }
                    return false;
                }

                // item not found check child items
                if (item.items && item.items.length > 0) {
                    this.resultSearchItem = item;
                    this.searchItem(item.items, url, (level !== undefined ? parseInt(level) + 1 : undefined));
                }
            });
        },
        searchItemForEntryPoint(arr, url, level) {
            arr.forEach((item) => {
                if (url === item.url || (!empty(item.urlAlternative) && url.indexOf(item.urlAlternative) !== -1)) {
                    this.parentItem = this.resultSearchItem;
                    this.activeItem = item;
                    return false;
                }
                if (!empty(item.entryPointsUrlsForMenuSearchForHiddenMenuItemsControllers)) {
                    for (let key in item.entryPointsUrlsForMenuSearchForHiddenMenuItemsControllers) {
                        if (item.entryPointsUrlsForMenuSearchForHiddenMenuItemsControllers[key] == url) {
                            this.parentItem = this.resultSearchItem;
                            this.activeItem = item;
                            return false;
                        }
                    }
                }

                // item not found check child items
                if (item.items && item.items.length > 0) {
                    this.resultSearchItem = item;
                    this.searchItemForEntryPoint(item.items, url, (level !== undefined ? parseInt(level) + 1 : undefined));
                }
            });
        },
        switchContent(url, newTab = false) {
            if (url === '/admin/logout' && this.appPart === 'admin') {
                logout('/admin/logout');
                return;
            }

            if (!this.isEmptyLink(url)) {
                if (newTab) {
                    window.open(url, '_blank');
                    return;
                }

                this.searchItem(this.items, url);
                if (url && this.$router.path !== url) {
                    this.$router.push(url);
                }

                this.closeMobileMenu();
                $(window)
                    .trigger('resize');
                this.updatePortalCounters();
            }
        },
        closeMobileMenu() {
            this.showMobileMenu(false);
        },
        setMobileItem({
            item,
            itemEl,
        }) {
            if (this.mobileItem === item) return;
            let sidebarTop = this.$el.getBoundingClientRect().top;
            let styles = window.getComputedStyle(itemEl);
            let paddingTop = parseFloat(styles.paddingTop);
            let paddingBottom = parseFloat(styles.paddingBottom);
            let height = itemEl.offsetHeight - (paddingTop + paddingBottom);
            let pos = itemEl.getBoundingClientRect().top - sidebarTop + paddingTop;

            this.unsetMobileItem();
            this.$nextTick(() => {
                this.mobileItem = item;
                this.mobileItemPos = pos;
                this.mobileItemHeight = height;
            });
        },
        unsetMobileItem(touchClick, hasChild) {
            if (!touchClick) {
                this.mobileItem = null;
                return;
            }
            if (this.mobileItemTimeout) clearTimeout(this.mobileItemTimeout);
            if (hasChild) return;
            this.mobileItemTimeout = setTimeout(() => {
                this.mobileItem = null;
            }, 600);
        },
        initParentOffsets() {
            let sidebarTop = this.$el.getBoundingClientRect().top;
            let sidebarLeft = this.$el.getBoundingClientRect().left;
            if (this.relative) {
                let parent = this.$el.parentElement;
                let parentTop = parent.getBoundingClientRect().top;
                let parentLeft = parent.getBoundingClientRect().left;
                this.parentHeight = `${parent.offsetHeight}px`;
                this.parentWidth = `${parent.offsetWidth}px`;
                this.parentOffsetTop = `${sidebarTop - parentTop}px`;
                this.parentOffsetLeft = `${sidebarLeft - parentLeft}px`;
            } else {
                this.parentOffsetTop = `${sidebarTop}px`;
                this.parentOffsetLeft = `${sidebarLeft}px`;
            }
        },
        onMouseLeave() {
            this.unsetMobileItem();
        },
        ...mapActions('menu_store', [
            'showMobileMenu',
        ]),
    },
    watch: {
        switchPage() {
            this.activateItemPageSwitch(null);
        },
        collapsedMenu(val) {
            if (this.isCollapsed === this.collapsedMenu) return;
            this.isCollapsed = val;
            this.unsetMobileItem();
            if (this.isCollapsed) {
                this.$nextTick(() => {
                    this.initParentOffsets();
                });
            }
        },
    },
    components: {
        XMenuItem,
    },
};
</script>
